import React from "react";
import { number, string } from "prop-types";

const ShareIcon = ({ bgColor = "#eeeeee", color = "#555555", width = 28, height = 28 }) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28">
    <circle fill={bgColor} cx="14" cy="14" r="14" />
    <path fill={color} d="M5.8,19.7c2.1-2.9,5-4.2,9.2-4.2v3.4l5.8-5.8l-5.8-5.8v3.3C9.1,11.3,6.6,15.5,5.8,19.7z" />
  </svg>
);

ShareIcon.propTypes = {
  width: number,
  height: number,
  bgColor: string,
  color: string
};

export default ShareIcon;
