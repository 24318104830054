export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  COLLECTION_PAGE: "collection-page",
  TAG_PAGE: "tag-page",
  NOTIFICATION_PAGE: "notification-page",
  SEARCH_PAGE: "search-page",
  SAVED_PAGE: "saved-page",
  STORY_PAGE: "story-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  AUTHOR_PAGE: "author-page",
  FORGOT_PASSWORD_PAGE: "forgot-password-page",
  RESET_PASSWORD_PAGE: "reset-password-page",
  CHANGE_PASSWORD_PAGE: "change-password-page",
  PROFILE_PAGE: "profile-page",
  EDIT_PROFILE_PAGE: "edit-profile-page",
  COMMENTS_PAGE: "my-comments",
  LOGIN_PAGE: "sign-in-page",
  SIGNUP_PAGE: "sign-up-page",
  POLL_COUNTER_PAGE: "poll-counter-page",
  UGC_PAGE: "ugc-page",
  FOR_YOU: "for-you",
});

// these are the sections required to generate routes for the deleted sections and handle redirections
export const DELETED_SECTION_ROUTES = [
  "গোলটেবিল",
  "gadget",
  "travel",
  "photo",
  "opinion",
  "singapore",
  "middle-east",
  "malaysia",
  "japan",
  "india",
  "europe",
  "china",
  "canada",
  "australia",
  "impact-journalism",
  "primary-and-secondary-education",
  "uk",
  "demo-like-home-author",
  "demo-like-home-tag",
  "demo-like-home-5",
  "demo-like-home-3",
  "technology",
  "socialmedia",
  "gallery",
  "itnews",
  "advice",
  "freelancing",
  "smart-somoy",
  "amader-kotha-shono",
  "roshalo",
  "alokito-chattogram",
  "sabar-age-shisu",
  "projonmo-dot-com",
  "cholti-bishow",
  "shilpo-o-sahitto",
  "anna-alo",
  "22221",
  "feature",
  "history",
  "olympic",
  "gallery",
  "science",
  "subeditorial",
  "video",
  "news",
  "ayojon",
  "nagorik-sangbad",
  "available",
  "other",
  "gallery",
  "occupation",
  "horoscope",
  "recipe",
  "jenenin",
  "home-decoration",
  "advice",
  "beautification",
  "style",
  "fashion",
  "gallery",
  "north-america",
  "australia",
  "arab-world",
  "tarunno",
  "chakri-bakri",
  "special-supplement",
  "pachmisheli",
  "we-are",
  "features",
  "review",
  "gallery",
  "cinema",
  "web",
  "higher-secondary-education",
  "budget",
  "corporate",
  "private-sector",
  "financial",
  "garments",
  "business",
  "america",
  "africa",
  "durporobash",
  "gallery",
  "government",
  "politics",
];

export const PAGE_TYPES_NOT_ADS = [
  "saved-page",
  "forgot-password-page",
  "reset-password-page",
  "change-password-page",
  "profile-page",
  "edit-profile-page",
  "sign-in-page",
  "sign-up-page",
  "static-page",
  "my-comments",
];

export const PREVIEW_PAGE_TYPES = ["story-public-preview-page", "story-preview", "home-preview"];

export const PAGE_TYPE_NOT_HEADER_FOOTER = [
  "forgot-password-page",
  "reset-password-page",
  "sign-in-page",
  "sign-up-page",
];

export const DOMAIN_SLUG_NOT_BREAKING_NEWS = [
  "kishoralo",
  "nagorik-sangbad",
  "bigganchinta",
  "protichinta",
  "bondhushava",
  "trust",
];

export const FOR_YOU_STATUS = {
  NOT_FOUND: "NOT-FOUND",
  NOT_LOGGED_IN: "NOT-LOGGED-IN",
  OK: "OK",
};

/* eslint-disable */
export const leftColumnAreas = (widget) => `\"left-1\" ${widget ? "widget" : ""} 
  \"left-2\" 
  \"left-3\" 
  \"left-4\" 
  \"left-5\" 
  \"left-6\" 
  \"left-7\" 
  \"left-8\"`;
/* eslint-disable no-alert */
export const DEBOUNCH_TIME = 3000;
