import React from "react";
import { string } from "prop-types";

const LinkedIn = ({ color1 = "#121212", color2 = "#fff" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
    <g data-name="Group 3676">
      <path d="M14 0A14 14 0 110 14 14 14 0 0114 0z" data-name="Ellipse 3" fill={color1} />
      <path
        d="M10.134 19.999H7.231v-9.346h2.9zM8.681 9.382a1.689 1.689 0 111.681-1.7 1.7 1.7 0 01-1.681 1.7zM21 19.999h-2.9v-4.55c0-1.084-.022-2.475-1.509-2.475-1.509 0-1.74 1.178-1.74 2.4v4.628h-2.9v-9.349h2.785v1.275h.041a3.05 3.05 0 012.746-1.509C20.46 10.418 21 12.353 21 14.865v5.134z"
        data-name="Path 180"
        fill={color2}
      />
    </g>
  </svg>
);

LinkedIn.propTypes = {
  color1: string,
  color2: string
};

export default LinkedIn;
