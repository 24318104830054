import React from "react";
import { string, object } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import { Facebook, Twitter, Instagram } from "../../../atoms/icons/StoryPageIcons";
import LinkedIn from "./LinkedIn";
import IconBase from "../../../icons";

import "./kishoraloFooter.m.css";

const KishoraloFooterBase = ({ currentPath, customSocialLinks, socialLinks }) => {
  const socialShareLinks = customSocialLinks.kishoralo || socialLinks;

  return (
    <div styleName="kishoralo-footer">
      <div styleName="social-links">
        <img src="/prothomalo/assets/kia-footer.jpg" alt="kishor alo" styleName="kia-footer-img" />
        <div styleName="first-three-icons">
          {socialShareLinks["facebook-url"] && (
            <Link
              aria-label="Kishoralo Facebook Link"
              href={socialShareLinks["facebook-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook color1="#001246" color2="#ffffff" />
            </Link>
          )}
          {socialShareLinks["twitter-url"] && (
            <Link
              aria-label="Kishoralo Twitter Link"
              href={socialShareLinks["twitter-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter color1="#001246" color2="#ffffff" />
            </Link>
          )}
          {socialShareLinks["instagram-url"] && (
            <Link
              aria-label="Kishoralo Instagram Link"
              href={socialShareLinks["instagram-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </Link>
          )}
          {socialShareLinks["linkedin-url"] && (
            <Link
              aria-label="Kishoralo Linkedin Link"
              href={socialShareLinks["linkedin-url"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedIn color1="#001246" color2="#ffffff" />
            </Link>
          )}
        </div>
      </div>
      <div styleName="footer-details">
        <div styleName="logo">
          <a aria-label="Kishoralo logo" href="/">
            <IconBase type="kishoralo-white" alt="kishoralo" className="kishoralo-logo" />
          </a>
        </div>
        <div styleName="address">
          <span className="line-1">কিশোর আলো</span>
          <span className="line-2">প্রগতি ইনস্যুরেন্স ভবন</span>
          <span className="line-3">২০-২১ কারওয়ান বাজার , ঢাকা ১২১৫</span>
          <span className="line-4">ফোন : ৮১৮০০৭৮-৮১। ফ্যাক্স : ৯১২১০৫২।</span>
          <span className="line-5">
            ই-মেইল:{" "}
            <a aria-label="editor@kishoralo.com" href="mailto:editor@kishoralo.com">
              editor@kishoralo.com
            </a>
          </span>
        </div>
        <div styleName="publisher">
          <span className="line-1">প্রকাশক : মতিউর রহমান</span>
          <span className="line-1">সম্পাদক : আনিসুল হক</span>
          <span className="line-1">সহসম্পাদক : আদনান মুকিত, মহিতুল আলম</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentPath: get(state, ["currentPathReducer", "currentPath"], null),
  customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  socialLinks: get(state, ["qt", "config", "social-links"], {}),
});

KishoraloFooterBase.propTypes = {
  currentPath: string,
  customSocialLinks: object,
  socialLinks: object,
};

export const KishoraloFooter = connect(mapStateToProps)(KishoraloFooterBase);

export default KishoraloFooter;
