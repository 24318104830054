import React from "react";
import { string } from "prop-types";

import Icons from "../../../../icons";

const DefaultFooterLogo = ({ domainSlug }) => {
  const width = global.isDesktop ? "200" : "180";
  const height = global.isDesktop ? "27" : "24.3";

  return (
    <a aria-label={`${domainSlug || "Prothom alo"} logo`} href="/">
      <Icons height={height} width={width} type={domainSlug === "nagorik-sangbad" ? "nagorik" : "prothomalo-com"} />
    </a>
  );
};

DefaultFooterLogo.propTypes = {
  domainSlug: string,
};

export default DefaultFooterLogo;
