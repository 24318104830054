import React from "react";
import { bool, number, string } from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";

import { SvgIcon } from "../../icons";

import { staticTexts } from "../../utils/Translate";
import { getStoryDate } from "../../utils/utils";

import "./liveText.m.css";

const LiveText = ({ lang = "bn", isClosed = true, lastPublishedAt, storyPage = false }) => {
  return storyPage && isClosed ? (
    <div className="live-text" styleName="live-text">
      {staticTexts(lang).liveEndText} {lastPublishedAt ? getStoryDate(lastPublishedAt, lang) : ""}
    </div>
  ) : (
    <span styleName="live-text" className="live-text">
      <SvgIcon type="live" styleName="icon" className="live-icon" />
      <span className="live-text-with-title">
        <span className="live-text live">{staticTexts(lang).liveText}</span>
      </span>
    </span>
  );
};

LiveText.propTypes = {
  storyPage: bool,
  lang: string,
  isClosed: bool,
  lastPublishedAt: number,
};

const mapStateToProps = (state) => ({
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], "bn"),
});

export default connect(mapStateToProps)(LiveText);
