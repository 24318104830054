import React from "react";
import { string } from "prop-types";

const CloseIcon = ({ color = "#555555", height = "20", width = "20" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="-5 -5 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M12.639 13.767L7 8.128l-5.639 5.638a.8.8 0 01-1.127-1.127l5.639-5.638L.234 1.362A.8.8 0 011.361.235L7 5.874 12.639.235a.8.8 0 011.127 1.127L8.127 7l5.639 5.638a.8.8 0 11-1.127 1.129z"
    />
  </svg>
);

CloseIcon.propTypes = {
  width: string,
  height: string,
  color: string,
};

export default CloseIcon;
