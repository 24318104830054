import React, { useEffect, useState } from "react";
import { array, object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import MobilePlayStore from "../../../molecules/MobilePlayStore";
import FooterMenuGroup from "../../../molecules/FooterMenuGroup";

import { Facebook, Instagram, Twitter } from "../../../atoms/icons/StoryPageIcons";
import Youtube from "./Youtube";
import DefaultFooterLogo from "./DefaultFooterLogo";

import { completeUrlSetter, getCurrentSite, toBanglaString } from "../../../utils/utils";
import { staticTexts } from "../../../utils/Translate";
import { menuGroups } from "../../../helper/api";
import { SvgIcon } from "../../../icons";

import "./defaultFooter.m.css";

const DefaultFooterBase = ({
  prothomaloMenuItems,
  lang,
  currentPath,
  domainSlug,
  micrositesMenuItems,
  sections,
  customSocialLinks,
  googleNewsLink,
}) => {
  const [authMenu, setAuthMenu] = useState({});
  const socialShareLinks = customSocialLinks[lang] || {};

  const footerBottom = prothomaloMenuItems.find((item) => item.title === "Footer Bottom");
  const footerBottomLinks = footerBottom && footerBottom.children.length > 0 ? footerBottom.children : null;
  const initialFooterBottomLinks =
    footerBottomLinks && footerBottomLinks.length > 1 ? footerBottomLinks.slice(0, footerBottomLinks.length) : null;

  const menuSetter = (group = getCurrentSite(currentPath)) => {
    menuGroups().then((res) => {
      const menu = get(res, ["menu-groups", group], []);
      const footerMenu = menu.items.filter((item) => item.title === "footer");
      const footerChildren =
        footerMenu.length > 0 ? menu.items.filter((item) => item["parent-id"] === footerMenu[0].id) : [];
      const footerChildrenObj = { children: footerChildren };
      setAuthMenu(completeUrlSetter(footerChildrenObj, sections));
    });
  };

  const defaultMenuItems = (siteMenuItems) => {
    const menuItems =
      siteMenuItems && siteMenuItems.length ? siteMenuItems.find((item) => item.title === "footer") : null;
    setAuthMenu(menuItems);
  };

  useEffect(() => {
    if (global.location.origin.includes("auth")) {
      if (getCurrentSite(currentPath) === "nagorik") {
        menuSetter("nagorik-sangbad");
      } else {
        defaultMenuItems(prothomaloMenuItems);
      }
    } else if (domainSlug === "nagorik") {
      defaultMenuItems(micrositesMenuItems);
    } else {
      defaultMenuItems(prothomaloMenuItems);
    }
  }, [micrositesMenuItems, currentPath]);

  const copyright = () => {
    const currentYear = new Date().getFullYear();
    let copyrightYears = `1998-${currentYear}`;
    if (lang === "bn") {
      copyrightYears = toBanglaString(copyrightYears);
      return `স্বত্ব © ${copyrightYears} প্রথম আলো`;
    }

    return `Copyright © ${copyrightYears} Prothom Alo`;
  };

  return (
    <div styleName="footer">
      <div styleName="row1" className="print-footer-row1">
        <div styleName="logo">
          <DefaultFooterLogo domainSlug={domainSlug} />
        </div>

        {authMenu && authMenu.children && authMenu.children.length > 0 ? (
          <div styleName="footer-links" className="print-footer-links">
            <FooterMenuGroup links={authMenu.children.slice(0, 18)} className={"footer-menu-group1"} />
          </div>
        ) : null}
      </div>
      <div styleName="row2" className="print-footer-row2">
        <div styleName="social-links">
          <span styleName="social-links__headline">{staticTexts(lang).followUsText}</span>
          <div styleName="social-links-links" className="social-links__items">
            {socialShareLinks["facebook-url"] && (
              <Link
                aria-label="Prothom Alo Facebook Link"
                href={socialShareLinks["facebook-url"]}
                target="_blank"
                rel="noopener noreferrer"
                styleName="social-icon-link"
              >
                <Facebook fillColor="#1877f2" />
              </Link>
            )}
            {socialShareLinks["twitter-url"] && (
              <Link
                aria-label="Prothom Alo Twitter Link"
                href={socialShareLinks["twitter-url"]}
                target="_blank"
                rel="noopener noreferrer"
                styleName="social-icon-link"
              >
                <Twitter fillColor="#1da1f2" />
              </Link>
            )}
            {socialShareLinks["instagram-url"] && (
              <Link
                aria-label="Prothom Alo Instagram Link"
                href={socialShareLinks["instagram-url"]}
                target="_blank"
                rel="noopener noreferrer"
                styleName="social-icon-link"
              >
                <Instagram color1="#eee" color2="#c32aa3" />
              </Link>
            )}
            {socialShareLinks["youtube-url"] && (
              <Link
                aria-label="Prothom Alo Youtube Link"
                href={socialShareLinks["youtube-url"]}
                className="youtube-icon"
                target="_blank"
                rel="noopener noreferrer"
                styleName="social-icon-link"
              >
                <Youtube color1="#ff0000" />
              </Link>
            )}
            {googleNewsLink && (
              <a
                aria-label="Google News"
                href={googleNewsLink}
                target="_blank"
                rel="noreferrer"
                styleName="social-icon-link"
              >
                <SvgIcon height="36" width="36" type="google-news-fill" />
              </a>
            )}
          </div>
        </div>
        <div styleName="app">
          <MobilePlayStore />
        </div>
      </div>
      {initialFooterBottomLinks && initialFooterBottomLinks.length > 0 ? (
        <div styleName="bottom-menu">
          <FooterMenuGroup links={initialFooterBottomLinks.slice(0, 8)} />
        </div>
      ) : null}
      <div styleName={`row3 ${lang === "en" ? "eng-row3" : ""}`}>
        <div styleName="copyright">{copyright()}</div>
        <div styleName="default-editor" className="print-default-editor">
          <span>{staticTexts(lang).sompadok}</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sections: get(state, ["qt", "config", "sections"], []),
  micrositesMenuItems: get(state, ["qt", "data", "navigationMenu", "menuItems"], []),
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], "bn"),
  prothomaloMenuItems: get(state, ["qt", "data", "navigationMenu", "prothomaloMenuItems"], []),
  customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  googleNewsLink: get(state, ["qt", "config", "publisher-attributes", "googleNewsLink"], ""),
  currentPath: get(state, ["currentPathReducer", "currentPath"], null),
});

DefaultFooterBase.propTypes = {
  prothomaloMenuItems: array,
  lang: string,
  customSocialLinks: object,
  currentPath: string,
  domainSlug: string,
  micrositesMenuItems: array,
  sections: array,
  googleNewsLink: string,
};

export const DefaultFooter = connect(mapStateToProps)(DefaultFooterBase);
