import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { array, func, object, string } from "prop-types";
import { sha256 } from "js-sha256";

import PopupOverlay from "../../../molecules/PopupOverlay";
import VerticalMenubar from "../VerticalMenubar";
import PaloHeader from "./PaloHeader";
import StaticHeader from "./StaticHeader";

import { breakingNewsAd, setBookmark, setJwtToken, setMember, setMobileFilter } from "../../../helper/actions";
import { getJwt, getUser } from "../../../helper/api";
import { checkRender, domainSlugFind, setCookie } from "../../../utils/utils";

import { PAGE_TYPE_NOT_HEADER_FOOTER } from "../../../../constants";

import "./uniformHeader.m.css";

const { BroadcastChannel } = require("broadcast-channel");

const UniformHeader = ({
  publisherAttributes,
  navigateToPage,
  member,
  setMember,
  setJwtToken,
  setBookmark,
  setMobileFilter,
  domainSlug,
  breakingNewsAd,
  hamburgerMenu,
  sections,
  pageType,
}) => {
  if (checkRender(PAGE_TYPE_NOT_HEADER_FOOTER, pageType)) return null;
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [showPopUp, setShowPopup] = useState(true);
  const [message, setMessage] = useState("");
  const [isVerticalMenubar, setVerticalMenuBar] = useState(false);
  const [domainslug, setDomainSlug] = useState(domainSlug);

  const previewEvent = useCallback((event) => {
    if (event && event.data && event.data.story) {
      const story = event.data.story;
      const slug = domainSlugFind(sections, story.sections[0]);
      setDomainSlug(slug);
    }
  });

  useEffect(() => {
    global.addEventListener("message", previewEvent);
    return () => global.removeEventListener("message", previewEvent);
  }, [previewEvent]);

  useEffect(() => {
    const channel = new BroadcastChannel("logout-channel");
    channel.onmessage = (msg) => {
      setMember(null);
      setJwtToken(null);
      setBookmark(null);
    };
  });

  const changePopupView = () => {
    const isHashPresent = global.location.href.substr(0, global.location.href.indexOf("#"));
    const finalRedirectUrl = new URL(isHashPresent);
    navigateToPage(finalRedirectUrl.pathname || "/");
    setShowPopup(false);
  };

  useEffect(() => {
    if (global.location && global.location.hash === "#email-verification") {
      setVerifySuccess(true);
    }

    if (global.location) {
      if (global.location.hash === "#email-verified") {
        setVerifySuccess(true);
        setMessage("Email verified.");
      } else if (global.location.hash === "#token-consumed") {
        setVerifySuccess(true);
        setMessage("The verification link is already used.");
      } else if (global.location.hash === "#invalid-token") {
        setVerifySuccess(true);
        setMessage("The verification link is invalid. Please request for a new link.");
      } else if (global.location.hash === "#internal-error") {
        setVerifySuccess(true);
        setMessage("Something went wrong. Please try again.");
      }
    }
  }, []);

  useEffect(() => {
    setVerticalMenuBar(false);
    isVerticalMenubar && setVerticalMenuBar(false);
  }, []);

  const toggleVerticalMenubar = () => {
    setVerticalMenuBar(!isVerticalMenubar);
    breakingNewsAd(!!isVerticalMenubar);
    setMobileFilter(false);
  };

  useEffect(() => {
    getUser()
      .then((response) => {
        setMember(response.user);
        if (global.localStorage && !global.localStorage.getItem("userID")) {
          localStorage.setItem("userID", sha256(response.user.email));
        }

        setCookie("userID", sha256(response.user.email));

        const integrationsId = get(publisherAttributes, ["integrations_id"], null);
        if (response.user["verification-status"]) {
          getJwt(integrationsId).then((res) => {
            res.status === 200 && setJwtToken(res.headers.get("x-integration-token"));
          });
        }
      })
      .catch((ex) => {});
  }, []);

  return (
    <div styleName="base" className={`${domainslug}-wrapper`}>
      {domainSlug ? (
        <StaticHeader
          toggleVerticalMenubar={toggleVerticalMenubar}
          isVerticalMenubar={isVerticalMenubar}
          domainSlug={domainslug}
        />
      ) : (
        <PaloHeader domainSlug={domainslug} lang={publisherAttributes.lang} publisherAttributes={publisherAttributes} />
      )}
      {isVerticalMenubar && (
        <VerticalMenubar
          toggleVerticalMenubar={toggleVerticalMenubar}
          hamburgerMenu={hamburgerMenu}
          domainSlug={domainslug}
        />
      )}
      {verifySuccess && showPopUp && member && (
        <PopupOverlay
          lang={publisherAttributes.lang}
          popupMessage={message}
          onClickHandler={changePopupView}
          buttonType="continue"
          buttonContent="Continue"
        />
      )}
      {verifySuccess && !member && showPopUp && (
        <PopupOverlay
          lang={publisherAttributes.lang}
          popupMessage={message}
          buttonType="login"
          buttonContent="Continue"
        />
      )}
    </div>
  );
};

UniformHeader.propTypes = {
  publisherAttributes: object,
  navigateToPage: func,
  member: object,
  setMember: func,
  setJwtToken: func,
  setBookmark: func,
  setMobileFilter: func,
  breakingNewsAd: func,
  hamburgerMenu: array,
  domainSlug: string,
  sections: array,
  pageType: string,
};

const mapStateToProps = (state) => ({
  sections: get(state, ["qt", "config", "sections"], []),
  publisherAttributes: get(state, ["qt", "config", "publisher-attributes"], {}),
  pageType: get(state, ["qt", "pageType"], ""),
  member: state.member || null,
  domainSlug: get(state, ["qt", "config", "domainSlug"]),
  hamburgerMenu: get(state, ["qt", "data", "navigationMenu", "menuItems"], []).find(
    (item) => item.title === "hamburger"
  )?.children,
});

const mapDispatchToProps = (dispatch) => ({
  navigateToPage: (url) => global.app.navigateToPage(dispatch, url),
  setMember: (member) => dispatch(setMember(member)),
  setJwtToken: (token) => dispatch(setJwtToken(token)),
  setBookmark: (obj) => dispatch(setBookmark(obj)),
  setMobileFilter: (flag) => dispatch(setMobileFilter(flag)),
  breakingNewsAd: (flag) => dispatch(breakingNewsAd(flag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UniformHeader);
