import React from "react";
import { string } from "prop-types";

import Icon from "../../icons";

import "./desktopLogo.m.css";

const DesktopLogo = ({ domainSlug, lang, className = "" }) => {
  const renderLogo = () => {
    if (!domainSlug) {
      return lang === "bn" ? (
        <Icon styleName="palo-bangla" type="palo-bangla" />
      ) : (
        <Icon styleName="palo-english" type="palo-english" />
      );
    } else if (domainSlug === "kishoralo") {
      return <Icon styleName="kishoralo" type="kishoralo" />;
    } else if (domainSlug === "protichinta") {
      return <Icon styleName="protichinta" type="protichinta-logo" />;
    } else if (domainSlug === "bigganchinta") {
      return <Icon styleName="bigganchinta" type="biggan-chinta" />;
    } else if (domainSlug === "nagorik-sangbad") {
      return <Icon styleName="nagorik-sangbad" type="nagorik" />;
    } else if (domainSlug === "trust") {
      return <Icon type="prothomalo-trust" styleName="trust" />;
    } else if (domainSlug === "bondhushava") {
      return <Icon styleName="bondhushava" type="bondhushava" />;
    }
  };

  return (
    <a className={className} styleName="base" aria-label="Prothom alo" href="/">
      <h2 styleName="logo" className="desktop-logo">
        {renderLogo()}
      </h2>
    </a>
  );
};

DesktopLogo.propTypes = {
  domainSlug: string,
  lang: string,
  className: string,
};

export default DesktopLogo;
