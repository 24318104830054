export const micrositeStyles = {
  kishoralo: {
    primaryBgColor: "#ffc107",
    hamburgerColor: "#001246",
    searchColor: "#001246",
    loginText: "#001246",
    loginBorder: "#001246",
    socialIconBg: "#eee",
    fontColor: "#121212",
    navbarBgColor: "#fff",
    navbarStickyColor: "#ffc107",
    hamburgerHeaderColor: "#fff",
  },
  protichinta: {
    primaryBgColor: "#fbf6de",
    hamburgerColor: "#121212",
    searchColor: "#555555",
    loginText: "#555555",
    loginBorder: "#999999",
    socialIconBg: "#fbf6de",
    fontColor: "#222",
    navbarBgColor: "#fbf6de",
    navbarStickyColor: "#fbf6de",
    hamburgerHeaderColor: "#fff",
  },
  "nagorik-sangbad": {
    navbarBgColor: "#34b8b5",
    navbarStickyColor: "#34b8b5",
    menuStyle: "#fff",
    stickyMenuStyle: "#fff",
    hamburgerColor: "#121212",
    searchColor: "#121212",
    hamburgerStickyColor: "#fff",
    searchStickyColor: "#fff",
    hamburgerHeaderColor: "#fff",
  },
  bondhushava: {
    navbarBgColor: "#d60000",
    navbarStickyColor: "#fff",
    menuStyle: "#fff",
    stickyMenuStyle: "#121212",
    hamburgerColor: "#121212",
    searchColor: "#121212",
    hamburgerStickyColor: "#121212",
    searchStickyColor: "#121212",
    hamburgerHeaderColor: "#fff",
  },
  trust: {
    navbarBgColor: "#e50019",
    navbarStickyColor: "#fff",
    menuStyle: "#fff",
    stickyMenuStyle: "#121212",
    hamburgerColor: "#121212",
    searchColor: "#121212",
    hamburgerStickyColor: "#121212",
    searchStickyColor: "#121212",
    hamburgerHeaderColor: "#fff",
  },
};
