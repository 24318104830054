import React from "react";
import { string, number } from "prop-types";

const SearchIcon = ({ width = 20, height = 20, domainSlug = "", color = "#001246" }) => {
  const getColor = () => {
    switch (domainSlug) {
      case "kishoralo":
        return "#001246";

      default:
        return "#121212";
    }
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <path
        d="M8.269 2.469a5.764 5.764 0 014.1 1.7 5.764 5.764 0 011.7 4.1 5.78 5.78 0 01-.347 1.984 5.778 5.778 0 01-.962 1.688l-.383.467-.472.381a5.735 5.735 0 01-3.638 1.283 5.764 5.764 0 01-4.1-1.7 5.764 5.764 0 01-1.7-4.1 5.764 5.764 0 011.7-4.1 5.764 5.764 0 014.1-1.7m0-2.467A8.269 8.269 0 00.003 8.269a8.269 8.269 0 008.269 8.269 8.233 8.233 0 005.187-1.829 1.755 1.755 0 00.444 1.717l3.114 3.114a1.745 1.745 0 001.238.511 1.745 1.745 0 001.238-.511 1.756 1.756 0 000-2.476l-3.114-3.114a1.744 1.744 0 00-1.237-.51 1.771 1.771 0 00-.468.063 8.234 8.234 0 001.867-5.234A8.269 8.269 0 008.272 0z"
        data-name="Path 223"
        fill={color || getColor()}
      />
    </svg>
  );
};

SearchIcon.propTypes = {
  color: string,
  width: number,
  height: number,
  domainSlug: string,
};

export default SearchIcon;
