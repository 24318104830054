import React, { memo } from "react";
import { ResponsiveImage } from "@quintype/components";
import { object } from "prop-types";
import { connect } from "react-redux";

import { imageParams } from "../../utils/utils";

const Image = ({ gumletDpr, ...ops }) => {
  const params = imageParams(gumletDpr);

  return React.createElement(ResponsiveImage, {
    "max-dpr": 2,
    type: "image/webp",
    sizes: "( max-width: 500px ) 98%, ( max-width: 875px ) 48%, 23%",
    imgParams: params,
    ...ops,
  });
};

Image.propTypes = {
  gumletDpr: object,
};

const mapStateToProps = (state) => ({
  gumletDpr: state.qt.config["publisher-attributes"]["gumlet-dpr"] || {},
});

export default connect(mapStateToProps)(memo(Image));
