import React, { useCallback, useEffect, useState } from "react";
import { object, string } from "prop-types";
import { get } from "lodash";
import { connect } from "react-redux";

import DfpContainer from "../../ads/dfp-container";
import { DfpComponent } from "../../ads/dfp-component";
import CloseIcon from "../../atoms/icons/close";

import { getSlotId } from "../../utils/utils";

import "./anchorAd.m.css";

const AnchorAd = ({ story, pageType, currentPath, lang }) => {
  const ads = {
    "home-page": {
      en: "EnPalo_HP_Floating_970x90",
      bn: "Mobile_HP_Anchor",
    },
    en: "EnPalo_Inner_Floating_970x90",
    bn: "Mobile_Article_Anchor",
  };

  const [showAnchorAd, setShowAnchorAd] = useState(false);
  const [stopScroll, setStopScroll] = useState(false);

  const scrollCallback = useCallback(() => {
    if (window.scrollY >= 400 && !stopScroll) {
      setShowAnchorAd(true);
      setStopScroll(true);
    }
  });

  useEffect(() => {
    const footer = document.getElementById("footer");
    if (footer && showAnchorAd) {
      footer.classList.add("show-anchor");
    } else if (footer && !showAnchorAd && footer.classList.contains("show-anchor")) {
      footer.classList.remove("show-anchor");
    }
  }, [showAnchorAd]);

  useEffect(() => {
    document.addEventListener("scroll", scrollCallback);

    return () => document.removeEventListener("scroll", scrollCallback);
  }, [scrollCallback]);

  if (!showAnchorAd) return null;

  return (
    <DfpContainer>
      <div className="ad-bottom-container" styleName="ad-bottom show-ad">
        <div styleName="ad-bottom-wrapper">
          <div title="close" styleName="anchor-close" onClick={() => setShowAnchorAd(false)}>
            <CloseIcon />
          </div>
          <div styleName="ad-wrapper" className="adsBox">
            <DfpComponent
              targetStory={story}
              type={ads?.[pageType]?.[lang] || ads[lang]}
              slotId={getSlotId("anchor-ad", currentPath, story?.id)}
            />
          </div>
        </div>
      </div>
    </DfpContainer>
  );
};

AnchorAd.propTypes = {
  pageType: string,
  story: object,
  currentPath: string,
  lang: string,
};

const mapStateToProps = (state) => ({
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], ""),
  pageType: get(state, ["qt", "pageType"], ""),
  currentPath: get(state, ["qt", "currentPath"], ""),
  story: state?.story?.story || state?.qt?.data?.story,
});

export default connect(mapStateToProps)(AnchorAd);
