import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { string, bool } from "prop-types";

import { DefaultFooter } from "./DefaultFooter";
import BondhushavaFooter from "./BondhushavaFooter";
import { ProtichintaFooter } from "./ProtichintaFooter";
import KishoraloFooter from "./KishoraloFooter";
import { BigganChintaFooter } from "./BigganChintaFooter";
import TrustFooter from "./TrustFooter";
import { getCurrentSite, checkRender } from "../../utils/utils";
import { PAGE_TYPE_NOT_HEADER_FOOTER } from "../../../constants";

const RenderFooter = ({ domainSlug, currentPath, pageType, isLoading }) => {
  if (checkRender(PAGE_TYPE_NOT_HEADER_FOOTER, pageType)) return null;

  const [renderFooter, setRenderFooter] = useState(null);

  useEffect(() => {
    window.addEventListener("load", function () {
      setRenderFooter(true);
    });
  }, []);

  if (isLoading || !renderFooter) return null;

  const getFooter = () => {
    if (domainSlug === "kishoralo" || getCurrentSite(currentPath) === "kishoralo") {
      return <KishoraloFooter />;
    } else if (domainSlug === "bondhushava" || getCurrentSite(currentPath) === "bondhushava") {
      return <BondhushavaFooter />;
    } else if (domainSlug === "protichinta" || getCurrentSite(currentPath) === "protichinta") {
      return <ProtichintaFooter />;
    } else if (domainSlug === "biggan-chinta" || getCurrentSite(currentPath) === "bigganchinta") {
      return <BigganChintaFooter />;
    } else if (domainSlug === "bigganchinta" || getCurrentSite(currentPath) === "bigganchinta") {
      return <BigganChintaFooter />;
    } else if (domainSlug === "trust" || getCurrentSite(currentPath) === "trust") {
      return <TrustFooter />;
    } else {
      return <DefaultFooter domainSlug={domainSlug} />;
    }
  };

  return <>{getFooter()}</>;
};

const mapStateToProps = (state) => ({
  currentPath: get(state, ["currentPathReducer", "currentPath"], null),
  domainSlug: get(state, ["qt", "config", "domainSlug"], null),
  pageType: get(state, ["qt", "pageType"], ""),
  isLoading: get(state, ["pageLoading"], false),
});

RenderFooter.propTypes = {
  currentPath: string,
  domainSlug: string,
  pageType: string,
  isLoading: bool,
};

export default connect(mapStateToProps)(RenderFooter);
