export const STATIC_TAGS = {
  "twitter:site": "@Prothomalo",
  "twitter:app:name:ipad": undefined,
  "twitter:app:name:googleplay": undefined,
  "twitter:app:id:googleplay": undefined,
  "twitter:app:name:iphone": undefined,
  "twitter:app:id:iphone": undefined,
  "apple-itunes-app": undefined,
  "google-play-app": undefined,
  "fb:app_id": "1499138263726489",
  "fb:pages": "163059227060505",
  "og:site_name": "Prothomalo",
  brand_name: "প্রথম আলো",
};

export const SOCIAL_TAG = {
  protichinta: {
    "fb:pages": "659156450832814",
    "og:site_name": "Protichinta",
    "twitter:site": "@Protichinta",
    brand_name: "প্রতিচিন্তা",
  },
  bigganchinta: {
    "fb:pages": "538696889672884",
    "og:site_name": "bigganchinta",
    "twitter:site": "@bigganchinta",
    brand_name: "বিজ্ঞানচিন্তা",
  },
  bondhushava: {
    "fb:pages": "307978799220778",
    "og:site_name": "Bondhushava.PA",
    "twitter:site": "@PABondhushava",
    brand_name: "বন্ধুসভা",
  },
  kishoralo: {
    "fb:pages": "252140831603198",
    "og:site_name": "kishor.alo",
    "twitter:site": "@kishor_alo",
    brand_name: "কিশোর আলো",
  },
  trust: {
    "fb:pages": "163607250349020",
    "og:site_name": "Palotrust",
    "twitter:site": "@prothomalotrust",
    brand_name: "প্রথম আলো ট্রাস্ট",
  },
  en: {
    "fb:pages": "1410366529268742",
    "og:site_name": "ProthomAloEnglish",
    "twitter:site": "@ProthomAlo",
    brand_name: "Prothom Alo",
  },
};

export const MICROSITES_OLD_URLS = [
  ["/kishoralo", "https://www.kishoralo.com"],
  ["/bondhushava", "https://www.bondhushava.com"],
  ["/protichinta", "https://www.protichinta.com"],
  ["/bigganchinta", "https://www.bigganchinta.com"],
  ["/trust", "https://trust.prothomalo.com"],
  ["/nagorik-sangbad", "https://nagorik.prothomalo.com"],
];

export const PAGES = {
  "author-page": "authorPage",
  "home-page": "homePage",
  "section-page": "sectionPage",
  "collection-page": "collectionPage",
  "tag-page": "tagPage",
  "notification-page": "notificationPage",
  "search-page": "searchPage",
  "saved-page": "savedPage",
  "story-page": "storyPage",
  "forgot-password-page": "forgotPasswordPage",
  "reset-password-page": "resetPasswordPage",
  "change-password-page": "changPasswordPage",
  "profile-page": "profilePage",
  "edit-profile-page": "editProfilePage",
  "my-comments": "myCommentPage",
  "sign-in-page": "loginPage",
  "sign-up-page": "signupPage",
  "poll-counter-page": "pollCounterPage",
  "ugc-page": "ugcPage",
};
