import AppstoreBlack from "./appstore-b.png";
import AppstoreColored from "./appstore-c.png";
import BigganChintaBlack from "./bigganchinta-black.png";
import BigganChintaColored from "./bigganchinta-colored.png";
import BondushavaBlack from "./bondhushava-black.png";
import BondushavaColored from "./bondhushava-colored.png";
import Chironton71Black from "./chironton71-black.png";
import Chironton71Colored from "./chironton71-colored.png";
import EnProthomaloBlack from "./en-prothomalo-black.png";
import EnProthomaloColored from "./en-prothomalo-colored.png";
import EPaperBlack from "./epaper-black.png";
import EPaperColored from "./epaper-colored.png";
import KishoraloBlack from "./kishoralo-black.png";
import KishoraloColored from "./kishoralo-colored.png";
import NagorikSongbadBlack from "./nagorik-songbad-black.png";
import NagorikSongbadColored from "./nagorik-songbad-colored.png";
import PlayStoreBlack from "./playstore-b.png";
import PlayStoreColored from "./playstore-c.png";
import ProthomaBlack from "./prothoma-black.png";
import ProthomaColored from "./prothoma-colored.png";
import ProthomaloBlack from "./prothomalo-black.png";
import ProthomaloColored from "./prothomalo-colored.png";
import ProtichintaBlack from "./protichinta-black.png";
import ProtichintaColored from "./protichinta-colored.png";
import TrustBlack from "./trust-black.png";
import TrustColored from "./trust-colored.png";

export default {
  "appstore-black": AppstoreBlack,
  "appstore-colored": AppstoreColored,
  "bigganchinta-black": BigganChintaBlack,
  "bigganchinta-colored": BigganChintaColored,
  "bondhushava-black": BondushavaBlack,
  "bondhushava-colored": BondushavaColored,
  "chironton71-black": Chironton71Black,
  "chironton71-colored": Chironton71Colored,
  "en-prothomalo-black": EnProthomaloBlack,
  "en-prothomalo-colored": EnProthomaloColored,
  "epaper-black": EPaperBlack,
  "epaper-colored": EPaperColored,
  "kishoralo-black": KishoraloBlack,
  "kishoralo-colored": KishoraloColored,
  "nagorik-songbad-black": NagorikSongbadBlack,
  "nagorik-songbad-colored": NagorikSongbadColored,
  "playstore-black": PlayStoreBlack,
  "playstore-colored": PlayStoreColored,
  "prothoma-black": ProthomaBlack,
  "prothoma-colored": ProthomaColored,
  "prothomalo-black": ProthomaloBlack,
  "prothomalo-colored": ProthomaloColored,
  "protichinta-black": ProtichintaBlack,
  "protichinta-colored": ProtichintaColored,
  "trust-black": TrustBlack,
  "trust-colored": TrustColored,
};
