import React, { useState, useEffect } from "react";
import { array, string, object } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import Icon from "../../../icons";
import FooterMenuGroup from "../../../molecules/FooterMenuGroup";
import { Facebook, Twitter } from "../../../atoms/icons/StoryPageIcons";

import { menuGroups } from "../../../helper/api";
import { completeUrlSetter } from "../../../utils/utils";

import "./protichintaFooter.m.css";

const ProtichintaCommunicationList = ({ className = "" }) => (
  <ul styleName="protichinta-communication-list" className={className}>
    <li styleName="communication-text">যোগাযোগ</li>
    <li styleName="anxiety-text">প্রতিচিন্তা</li>
    <li>প্রগতি ইনস্যুরেন্স ভবন</li>
    <li>২০-২১ কারওয়ান বাজার , ঢাকা ১২১৫</li>
    <li>ফোন: ৮১৮০০৭৮-৮১</li>
    <li>ইমেইল: protichinta@gmail.com</li>
  </ul>
);

ProtichintaCommunicationList.propTypes = {
  className: string,
};

const ProtichintaFooterBase = ({ protichintaMenuItems, currentPath, customSocialLinks, socialLinks, sections }) => {
  const socialShareLinks = customSocialLinks.protichinta || socialLinks;
  const [authMenu, setAuthMenu] = useState({});

  useEffect(() => {
    if (global.location.origin.includes("auth")) {
      menuGroups().then((res) => {
        const menu = get(res, ["menu-groups", "protichinta"], []);
        const footerMenu = menu.items.filter((item) => item.title === "footer");
        const footerChildren =
          footerMenu.length > 0 ? menu.items.filter((item) => item["parent-id"] === footerMenu[0].id) : [];
        const footerChildrenObj = { children: footerChildren };
        setAuthMenu(completeUrlSetter(footerChildrenObj, sections));
      });
    } else if (protichintaMenuItems) {
      const menuItems =
        protichintaMenuItems && protichintaMenuItems.length
          ? protichintaMenuItems.find((item) => item.title === "footer")
          : null;
      setAuthMenu(menuItems);
    }
  }, [protichintaMenuItems]);

  return (
    <div styleName="wrapper">
      <div styleName="menu-items-with-logo">
        <div styleName="protichinta-logo">
          <a aria-label="Protichinta logo" href="/">
            <Icon type="protichinta-logo" styleName="protichinta-icon" />
          </a>
        </div>
        <div styleName={`menu-items-container `}>
          {authMenu && authMenu.children && authMenu.children.length > 0 && (
            <FooterMenuGroup links={authMenu.children.slice(0, 10)} />
          )}
          <div>
            <div styleName="social-icons">
              {socialShareLinks["facebook-url"] && (
                <Link
                  aria-label="Protichinta Facebook Link"
                  href={socialShareLinks["facebook-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook color1="#781619" color2="#eee" />
                </Link>
              )}
              {socialShareLinks["twitter-url"] && (
                <Link
                  aria-label="Protichinta Twitter Link"
                  href={socialShareLinks["twitter-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter color1="#781619" color2="#eee" />
                </Link>
              )}
            </div>
            <div styleName="about-protichinta">
              <ProtichintaCommunicationList className="communication-list-mobile" />
              <ul styleName="about-protichinta-data">
                <li>সম্পাদক ও প্রকাশক: মতিউর রহমান</li>
                <li>নির্বাহী সম্পাদক: খন্দকার সাখাওয়াত আলী</li>
                <li>সহকারী সম্পাদক: খলিলউল্লাহ্‌</li>
              </ul>
            </div>
          </div>
          <ProtichintaCommunicationList className="communication-list-desktop" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sections: get(state, ["qt", "config", "sections"], []),
  protichintaMenuItems: get(state, ["qt", "data", "navigationMenu", "menuItems"], []),
  currentPath: get(state, ["currentPathReducer", "currentPath"], null),
  customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  socialLinks: get(state, ["qt", "config", "social-links"], {}),
});

ProtichintaFooterBase.propTypes = {
  protichintaMenuItems: array,
  currentPath: string,
  customSocialLinks: object,
  socialLinks: object,
  sections: array,
};

export const ProtichintaFooter = connect(mapStateToProps)(ProtichintaFooterBase);
