import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import { string, object, func } from "prop-types";

import Icons from "./icons";

import "./icons.m.css";

if (require.context) {
  const req = require.context("../../../assets/icons/", true, /\.svg$/);
  req.keys().forEach((filename) => req(filename));
}

const IconBase = ({ type = "", height, width, onClick, className = "", iconSpritePath, iconStyle = {} }) => {
  if (!type) return null;

  return (
    <svg
      onClick={onClick}
      className={className}
      styleName="svg-icon"
      style={iconStyle}
      preserveAspectRatio="none"
      height={height}
      width={width}
    >
      <use href={`${iconSpritePath}#${String(type).toLowerCase()}`} />
    </svg>
  );
};

export const SvgIcon = ({ type = "", ...props }) => {
  const Icon = Icons[type];
  if (!Icon) return null;

  return <Icon {...props} />;
};

const mapStateToProps = (state) => ({
  iconSpritePath: get(state, ["qt", "config", "iconSpritePath"]),
});

SvgIcon.propTypes = {
  type: string,
};

IconBase.propTypes = {
  type: string,
  className: string,
  iconSpritePath: string,
  iconStyle: object,
  onClick: func,
  height: string,
  width: string,
};

export default connect(mapStateToProps)(IconBase);
