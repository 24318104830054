import React from "react";
import { Link } from "@quintype/components";
import { string } from "prop-types";
import { useSelector } from "react-redux";

import { getCookie, linkTarget, loginUrlFromStory, validURL } from "../../utils/utils";

const Anchor = ({ href = "/", target, access, ...props }) => {
  if (href === "#") {
    return React.createElement("div", { ...props });
  }

  const host = useSelector((state) => state?.qt?.currentHostUrl);
  const member = useSelector((state) => state.member);
  const publisherAttributes = useSelector((state) => state?.qt?.config?.["publisher-attributes"]) || {};

  target = target || linkTarget(host, href) || "_self";

  if (!validURL(href)) {
    const slush = href?.match(/^\//gm);
    href = slush ? href : "/" + href;
  }

  if (access === "login" && !(getCookie("userID") || member)) {
    href = loginUrlFromStory(publisherAttributes, href);
  }

  return React.createElement(target === "_blank" ? "a" : Link, { target, href, ...props });
};

Anchor.defaultProps = {
  "aria-label": "",
};

Anchor.propTypes = {
  href: string,
  target: string,
  "aria-label": string,
  access: string,
};
export default Anchor;
