export const ENG_GOOGLE_PLAYSTORE = "https://play.google.com/store/apps/details?id=com.prothomalo.english";
export const ENG_APPLE_PLAYSTORE = "https://apps.apple.com/us/app/english-news-prothom-alo/id1289463331";
export const BN_GOOGLE_PLAYSTORE = "https://play.google.com/store/apps/details?id=com.mcc.prothomalo";
export const BN_APPLE_PLAYSTORE = "https://apps.apple.com/us/app/bangla-newspaper-prothom-alo/id548596669";
export const NA_APPLE_PLAYSTORE = "https://apps.apple.com/us/app/prothom-alo-north-america/id1289436839";

export const storyTemplatesList = [
  { type: "all", bn: "সব" },
  { type: "text,team-bio,live-blog,,listicle,poll,live-blog", bn: "টেক্সট" },
  { type: "photo,gallery", bn: "ছবি" },
  { type: "video", bn: "ভিডিও" },
  { type: "interview", bn: "সাক্ষাৎকার" },
];

export const paloStoryTypes = [
  { type: "all", en: "All", bn: "সব" },
  { type: "text,team-bio,listicle,", en: "Text", bn: "টেক্সট" },
  { type: "photo,gallery", en: "Photo", bn: "ছবি" },
  { type: "video", en: "Video", bn: "ভিডিও" },
  { type: "live-blog", en: "Live blog", bn: "সরাসরি" },
  { type: "interview", en: "Interview", bn: "সাক্ষাৎকার" },
];

export const sortingList = [
  { sort: "default", en: "Relevant", bn: "প্রাসঙ্গিক" },
  { sort: "latest-published", en: "Latest", bn: "নতুন থেকে পুরোনো" },
  { sort: "published-at:asc", en: "Oldest", bn: "পুরোনো থেকে নতুন" },
];

export const storyFields =
  "headline,subheadline,slug,url,tags,hero-image-s3-key,hero-image-caption,hero-image-metadata,last-published-at,alternative,authors,author-name,author-id,sections,story-template,metadata,hero-image-attribution,access";

export const prayerDistricts = {
  getDistrict(areaName) {
    return this.areas.find(({ name }) => name.toLowerCase() === areaName?.toLowerCase());
  },

  get districts() {
    return this.areas;
  },

  areas: [
    {
      name: "Coxsbazar",
      bnName: "কক্সবাজার",
      sehri: -1,
      iftar: -10,
    },
    {
      name: "Kishoreganj",
      bnName: "কিশোরগঞ্জ",
      sehri: -3,
      iftar: -1,
    },
    {
      name: "Comilla",
      bnName: "কুমিল্লা",
      sehri: -3,
      iftar: -4,
    },
    {
      name: "Kushtia",
      bnName: "কুষ্টিয়া",
      sehri: 5,
      iftar: 5,
    },
    {
      name: "Kurigram",
      bnName: "কুড়িগ্রাম",
      sehri: -2,
      iftar: 7,
    },
    {
      name: "Khagrachhari",
      bnName: "খাগড়াছড়ি",
      sehri: -5,
      iftar: -8,
    },
    {
      name: "Khulna",
      bnName: "খুলনা",
      sehri: 6,
      iftar: 2,
    },
    {
      name: "Gaibandha",
      bnName: "গাইবান্ধা",
      sehri: -1,
      iftar: 6,
    },
    {
      name: "Gazipur",
      bnName: "গাজীপুর",
      sehri: -1,
      iftar: 0,
    },
    {
      name: "Gopalganj",
      bnName: "গোপালগঞ্জ",
      sehri: 4,
      iftar: 1,
    },
    {
      name: "Chattogram",
      bnName: "চট্টগ্রাম",
      sehri: -2,
      iftar: -8,
    },
    {
      name: "Chandpur",
      bnName: "চাঁদপুর",
      sehri: 0,
      iftar: -2,
    },
    {
      name: "Chapainawabganj",
      bnName: "চাঁপাইনবাবগঞ্জ",
      sehri: 6,
      iftar: 10,
    },
    {
      name: "Chuadanga",
      bnName: "চুয়াডাঙ্গা",
      sehri: 6,
      iftar: 6,
    },
    {
      name: "Jamalpur",
      bnName: "জামালপুর",
      sehri: -2,
      iftar: 4,
    },
    {
      name: "Joypurhat",
      bnName: "জয়পুরহাট",
      sehri: 2,
      iftar: 8,
    },
    {
      name: "Jhalakathi",
      bnName: "ঝালকাঠি",
      sehri: 3,
      iftar: -1,
    },
    {
      name: "Jhenaidah",
      bnName: "ঝিনাইদহ",
      sehri: 5,
      iftar: 5,
    },
    {
      name: "Tangail",
      bnName: "টাঙ্গাইল",
      sehri: 0,
      iftar: 2,
    },
    {
      name: "Thakurgaon",
      bnName: "ঠাকুরগাঁও",
      sehri: 2,
      iftar: 12,
    },
    {
      name: "Dhaka",
      bnName: "ঢাকা",
      sehri: 0,
      iftar: 0,
    },
    {
      name: "Dinajpur",
      bnName: "দিনাজপুর",
      sehri: 2,
      iftar: 10,
    },
    {
      name: "Naogaon",
      bnName: "নওগাঁ",
      sehri: 3,
      iftar: 8,
    },
    {
      name: "Narail",
      bnName: "নড়াইল",
      sehri: 5,
      iftar: 2,
    },
    {
      name: "Narsingdi",
      bnName: "নরসিংদী",
      sehri: -2,
      iftar: -1,
    },
    {
      name: "Natore",
      bnName: "নাটোর",
      sehri: 4,
      iftar: 7,
    },
    {
      name: "Narayanganj",
      bnName: "নারায়ণগঞ্জ",
      sehri: 0,
      iftar: -1,
    },
    {
      name: "Nilphamari",
      bnName: "নীলফামারী",
      sehri: 1,
      iftar: 10,
    },
    {
      name: "Netrokona",
      bnName: "নেত্রকোণা",
      sehri: -5,
      iftar: 0,
    },
    {
      name: "Noakhali",
      bnName: "নোয়াখালী",
      sehri: -1,
      iftar: -4,
    },
    {
      name: "Panchagarh",
      bnName: "পঞ্চগড়",
      sehri: 1,
      iftar: 12,
    },
    {
      name: "Patuakhali",
      bnName: "পটুয়াখালী",
      sehri: 4,
      iftar: -2,
    },
    {
      name: "Pabna",
      bnName: "পাবনা",
      sehri: 4,
      iftar: 5,
    },
    {
      name: "Pirojpur",
      bnName: "পিরোজপুর",
      sehri: 5,
      iftar: 0,
    },
    {
      name: "Faridpur",
      bnName: "ফরিদপুর",
      sehri: 2,
      iftar: 2,
    },
    {
      name: "Feni",
      bnName: "ফেনী",
      sehri: -3,
      iftar: -5,
    },
    {
      name: "Bogura",
      bnName: "বগুড়া",
      sehri: 1,
      iftar: 6,
    },
    {
      name: "Barguna",
      bnName: "বরগুনা",
      sehri: 5,
      iftar: -2,
    },
    {
      name: "Barisal",
      bnName: "বরিশাল",
      sehri: 2,
      iftar: -2,
    },
    {
      name: "Bagerhat",
      bnName: "বাগেরহাট",
      sehri: 5,
      iftar: 1,
    },
    {
      name: "Bandarban",
      bnName: "বান্দরবান",
      sehri: -4,
      iftar: -10,
    },
    {
      name: "Brahmanbaria",
      bnName: "ব্রাহ্মণবাড়িয়া",
      sehri: -4,
      iftar: -3,
    },
    {
      name: "Bhola",
      bnName: "ভোলা",
      sehri: 2,
      iftar: -3,
    },
    {
      name: "Magura",
      bnName: "মাগুরা",
      sehri: 4,
      iftar: 3,
    },
    {
      name: "Madaripur",
      bnName: "মাদারীপুর",
      sehri: 2,
      iftar: 0,
    },
    {
      name: "Manikganj",
      bnName: "মানিকগঞ্জ",
      sehri: 1,
      iftar: 2,
    },
    {
      name: "Munshiganj",
      bnName: "মুন্সিগঞ্জ",
      sehri: 0,
      iftar: -1,
    },
    {
      name: "Meherpur",
      bnName: "মেহেরপুর",
      sehri: 7,
      iftar: 7,
    },
    {
      name: "Moulvibazar",
      bnName: "মৌলভীবাজার",
      sehri: -8,
      iftar: -4,
    },
    {
      name: "Mymensingh",
      bnName: "ময়মনসিংহ",
      sehri: -3,
      iftar: 1,
    },
    {
      name: "Jashore",
      bnName: "যশোর",
      sehri: 6,
      iftar: 4,
    },
    {
      name: "Rangpur",
      bnName: "রংপুর",
      sehri: -1,
      iftar: 8,
    },
    {
      name: "Rangamati",
      bnName: "রাঙ্গামাটি",
      sehri: -4,
      iftar: -9,
    },
    {
      name: "Rajbari",
      bnName: "রাজবাড়ী",
      sehri: 4,
      iftar: 4,
    },
    {
      name: "Rajshahi",
      bnName: "রাজশাহী",
      sehri: 5,
      iftar: 8,
    },
    {
      name: "Lakshmipur",
      bnName: "লক্ষ্মীপুর",
      sehri: -1,
      iftar: -3,
    },
    {
      name: "Lalmonirhat",
      bnName: "লালমনিরহাট",
      sehri: -2,
      iftar: 7,
    },
    {
      name: "Shariatpur",
      bnName: "শরীয়তপুর",
      sehri: 2,
      iftar: -1,
    },
    {
      name: "Sherpur",
      bnName: "শেরপুর",
      sehri: -2,
      iftar: 3,
    },
    {
      name: "Satkhira",
      bnName: "সাতক্ষীরা",
      sehri: 8,
      iftar: 4,
    },
    {
      name: "Sirajganj",
      bnName: "সিরাজগঞ্জ",
      sehri: 1,
      iftar: 4,
    },
    {
      name: "Sylhet",
      bnName: "সিলেট",
      sehri: -9,
      iftar: -4,
    },
    {
      name: "Sunamganj",
      bnName: "সুনামগঞ্জ",
      sehri: -7,
      iftar: -2,
    },
    {
      name: "Habiganj",
      bnName: "হবিগঞ্জ",
      sehri: -6,
      iftar: -3,
    },
  ],
};
