import React, { useEffect, useRef } from "react";
import { array, bool, func, object, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import NavbarSearch from "../NavbarSearch";
import Navbar from "../Navbar";
import { Email, Facebook, Instagram, Twitter } from "../../../atoms/icons/StoryPageIcons";

import WatsAppIcon from "../../../atoms/icons/WatsAppIcon";
import Youtube from "../../../layouts/footer/DefaultFooter/Youtube";
import { WhiteCloseIcon } from "../../../basic/icons/white-close-icon";
import Icon from "../../../icons";

import { getDateFromUtils } from "../../../utils/utils";
import { staticTexts } from "../../../utils/Translate";

import "./verticalMenubar.m.css";

const VerticalMenubar = ({ toggleVerticalMenubar, hamburgerMenu, lang, domainSlug, dot, customSocialLinks }) => {
  const socialShareLinks = customSocialLinks[domainSlug || lang] || {};
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    const anchorClicked = event.target.closest("#anchor-ad");

    if (ref.current && !ref.current.contains(event.target) && !anchorClicked) {
      toggleVerticalMenubar();
    }
  };

  useEffect(() => {
    if (global.isDesktop) {
      ref.current.classList.remove("preload");

      document.addEventListener("click", handleClickOutside, true);

      return () => {
        document.removeEventListener("click", handleClickOutside, true);
        toggleVerticalMenubar();
      };
    }
  });

  return (
    <div styleName="vertical-menubar-wrapper" className="vertical-menubar-wrapper">
      {global.isDesktop && (
        <div onClick={toggleVerticalMenubar} styleName="close" className="button-close">
          <WhiteCloseIcon width={30} height={30} />
        </div>
      )}

      <div ref={ref} className="vertical-menubar preload" styleName={`${lang}-menubar vertical-menubar slide-in`}>
        {!global.isDesktop && (
          <div styleName="vertical-menubar-header">
            <div styleName="date-notification-container">
              <time styleName="date">{getDateFromUtils(lang)}</time>
            </div>
          </div>
        )}
        {!global.isDesktop && <NavbarSearch lang={lang} toggleVerticalMenubar={toggleVerticalMenubar} />}
        <div styleName="navbar-container">
          <Navbar
            menu={hamburgerMenu}
            domainSlug={domainSlug}
            verticalNavbar={true}
            toggleVerticalMenubar={toggleVerticalMenubar}
          />
          <div styleName="bottom-container">
            <span styleName="social-links__headline">{staticTexts(lang).followUsText}</span>
            <div
              styleName={`social-share-icons ${
                Object.keys(socialShareLinks).length && Object.keys(socialShareLinks).length > 3
                  ? "justify-space-between"
                  : "justify-space-around"
              }`}
            >
              {socialShareLinks["facebook-url"] && (
                <Link
                  aria-label="Facebook Link"
                  href={socialShareLinks["facebook-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook height={global.isMobile ? "48" : "28"} width={global.isMobile ? "48" : "28"} />
                </Link>
              )}
              {socialShareLinks["twitter-url"] && (
                <Link
                  aria-label="Twitter Link"
                  href={socialShareLinks["twitter-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Twitter height={global.isMobile ? "48" : "28"} width={global.isMobile ? "48" : "28"} />
                </Link>
              )}
              {socialShareLinks["messenger-url"] && (
                <Link
                  aria-label="Messenger Link"
                  href={socialShareLinks["messenger-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon type="messenger" alt="messenger logo" styleName="messenger" />
                </Link>
              )}
              {socialShareLinks["telegram-url"] && (
                <Link
                  aria-label="Telegram Link"
                  href={socialShareLinks["telegram-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon type="launcher" alt="launcher logo" styleName="launcher" />
                </Link>
              )}
              {socialShareLinks["whatsapp-url"] && (
                <Link
                  aria-label="Whatsapp Link"
                  href={socialShareLinks["whatsapp-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WatsAppIcon />
                </Link>
              )}
              {socialShareLinks["email-url"] && (
                <Link
                  aria-label="Email Link"
                  href={socialShareLinks["email-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Email />
                </Link>
              )}
              {socialShareLinks["youtube-url"] && (
                <Link
                  aria-label="Youtube Link"
                  href={socialShareLinks["youtube-url"]}
                  className="youtube-icon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Youtube height={global.isMobile ? "48" : "28"} width={global.isMobile ? "48" : "28"} />
                </Link>
              )}
              {socialShareLinks["instagram-url"] && (
                <Link
                  aria-label="Instagram Link"
                  href={socialShareLinks["instagram-url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram height={global.isMobile ? "48" : "28"} width={global.isMobile ? "48" : "28"} />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VerticalMenubar.propTypes = {
  toggleVerticalMenubar: func,
  hamburgerMenu: array,
  domainSlug: string,
  lang: string,
  customSocialLinks: object,
  dot: bool,
};

const mapStateToProps = (state) => ({
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], ""),
  customSocialLinks: get(state, ["qt", "config", "custom-social-links"], {}),
  domainSlug: get(state, ["qt", "config", "domainSlug"], ""),
});

export default connect(mapStateToProps, null)(VerticalMenubar);
