import React from "react";
import { Link } from "@quintype/components";
import { string } from "prop-types";

import { SvgIcon } from "../../icons";

import "./epaper.m.css";

const Epaper = ({ className = "" }) => {
  return (
    <Link href="https://epaper.prothomalo.com" styleName="base" className={`epaper ${className}`} target="_blank">
      <SvgIcon type="newspaper" styleName="newspaper" />
      <h4 styleName="text">ই-পেপার</h4>
    </Link>
  );
};

Epaper.propTypes = {
  className: string,
};

export default Epaper;
