import React from "react";
import { array, bool, object, string } from "prop-types";

import Icon from "../../icons";
import Image from "../Image";

import "./contributorImage.m.css";

const ContributorImage = ({
  authorDisplayName = "",
  authorObj = {},
  customStyle = false,
  blurStyle = false,
  widths = [100],
  memberProfilePic = false,
  role = "",
  defaultImage = true,
}) => {
  const authorImg = authorObj["avatar-s3-key"] || authorObj["avatar-url"];
  const customWrapperStyle = customStyle ? "contributor-base-wrapper-custom" : "";
  const blurImageStyle = blurStyle ? "contributor-image-blur contributor-image" : "contributor-image";

  const publicAuthor = role === "Public Author";

  const noAuthorImage = () => {
    return <Icon styleName={blurImageStyle} className="contributor-image" type="default-author-image" />;
  };

  const s3AuthorImage = () => {
    return (
      <Image
        slug={authorObj["avatar-s3-key"]}
        metadata={authorObj.metadata || ""}
        defaultWidth={100}
        widths={widths}
        imgParams={{ auto: ["format", "compress"], fmt: "webp", dpr: "1.0" }}
        styleName={blurImageStyle}
        className="contributor-image"
        alt={authorObj.name + " Contributor image"}
      />
    );
  };

  const avatarAuthorImage = () => {
    return memberProfilePic ? (
      <img
        styleName={blurImageStyle}
        className="contributor-image"
        src={authorObj["avatar-url"]}
        alt={authorObj.name}
      />
    ) : (
      <img
        styleName={blurImageStyle}
        className="contributor-image"
        src={authorObj["avatar-url"]}
        alt={authorObj.name}
      />
    );
  };

  const isShowImage = (!authorDisplayName && authorImg && publicAuthor) || memberProfilePic;
  const hasImage = authorImg || defaultImage;

  return hasImage ? (
    <div className="contributor-base-wrapper" styleName={customWrapperStyle}>
      {isShowImage ? (authorObj["avatar-s3-key"] ? s3AuthorImage() : avatarAuthorImage()) : noAuthorImage()}
    </div>
  ) : null;
};

ContributorImage.propTypes = {
  authorObj: object,
  widths: array,
  authorDisplayName: string,
  slug: string,
  customStyle: bool,
  blurStyle: bool,
  memberProfilePic: bool,
  role: string,
  defaultImage: bool,
};

export default ContributorImage;
