import React from "react";
import { object, string } from "prop-types";

import LiveText from "../LiveText";
import Icons, { SvgIcon } from "../../icons";
import Anchor from "../Anchor";

import { getHeadline } from "../../utils/utils";

import "./header-headline.m.css";

const HeaderHeadline = ({ story, className = "" }) => {
  if (!story) return null;

  const isLive = !story.metadata?.["is-closed"] && story["story-template"] === "live-blog";

  return (
    <Anchor href={story.url} aria-label={story.title} access={story.access}>
      <h2 className={className}>
        <p styleName="headline" className="headline">
          {story?.access === "login" && <Icons type="star" styleName="icon" />}
          {isLive ? (
            <>
              <LiveText isClosed={story.metadata?.["is-closed"]} lastPublishedAt={story["last-published-at"]} />
              <span styleName="dot" className="dot">
                &#x2022;
              </span>
            </>
          ) : (
            <SvgIcon styleName="icon" type={story["story-template"]} />
          )}
          {getHeadline(story)}
        </p>
      </h2>
    </Anchor>
  );
};

HeaderHeadline.propTypes = {
  story: object,
  className: string,
};

export default HeaderHeadline;
