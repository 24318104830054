import React from "react";
import { string } from "prop-types";

const FacebookIcon = ({ color = "#eee" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20">
    <g data-name="Group 582">
      <path
        d="M10.363 0H7.778A4.54 4.54 0 003 4.9v2.255H.414A.415.415 0 000 7.569v3.268a.415.415 0 00.414.414H3v8.253a.415.415 0 00.414.414h3.392a.415.415 0 00.414-.414v-8.251h3.04a.415.415 0 00.414-.414V7.571a.424.424 0 00-.414-.414H7.219v-1.9c0-.91.228-1.386 1.407-1.386h1.738a.415.415 0 00.414-.414V.414A.444.444 0 0010.363 0z"
        data-name="Path 14"
        fill={color}
      />
    </g>
  </svg>
);

FacebookIcon.propTypes = {
  color: string,
};

export default FacebookIcon;
