import React from "react";
import { array, object, string, func, bool } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { MenuItem } from "../helper-components";

import "./navbar.m.css";

const Navbar = ({ menu = [], navbarMenuStyle, toggleVerticalMenubar, lang, verticalNavbar = false }) => {
  const homeMenuItem = {
    title: lang === "en" ? "Home" : "প্রচ্ছদ",
    completeUrl: "/",
  };

  if (verticalNavbar) {
    menu = [homeMenuItem, ...menu];
  }

  return (
    <nav styleName="navbar" className="navbar">
      <ul styleName="navbar-list" className="navbar-list">
        {menu.map((item, index) => (
          <li
            key={index}
            styleName={`menu-item  ${item["item-type"] === "placeholder" ? "menu-item-placeholder" : ""}`}
            className="menu-item"
            style={navbarMenuStyle}
          >
            <div>
              <MenuItem item={item} itemClick={toggleVerticalMenubar} />
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Navbar.propTypes = {
  menu: array,
  navbarMenuStyle: object,
  toggleVerticalMenubar: func,
  dataTagCategory: string,
  lang: string,
  verticalNavbar: bool,
};

const mapStateToProps = (state) => ({
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], ""),
});

export default connect(mapStateToProps)(Navbar);
