import React, { useEffect, useRef, useState } from "react";
import { array, bool, func, string } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import HamburgerIcon from "../../../atoms/icons/hamburger";
import SearchIcon from "../../../atoms/icons/search-icon";
import NavbarSearch from "../NavbarSearch";
import DesktopLogo from "../../../atoms/DesktopLogo";
import Navbar from "../Navbar";
import Headroom from "./Headroom";
import LoginContainer from "./LoginContainer";

import { micrositeStyles } from "./header-utils";
import { getDateFromUtils } from "../../../utils/utils";

import "./staticHeader.m.css";

const StaticHeader = ({
  toggleVerticalMenubar,
  isVerticalMenubar,
  domainMenuItemsObj,
  domainSlug,
  donateButtonLink,
  headerDown,
  loading,
}) => {
  const [domainStyle, setDomainStyle] = useState({});
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [sticky, setSticky] = useState(false);
  const ref = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (domainSlug) {
      setDomainData(domainSlug);
    }
  }, [domainSlug]);

  const setDomainData = (domainName) => {
    setDomainStyle(micrositeStyles[domainName] || {});
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbarTopDistance = Math.abs(scrollRef.current?.getBoundingClientRect().top);
      navbarTopDistance < 107 ? setSticky(false) : setSticky(true);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.addEventListener("scroll", handleScroll);
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowSearchIcon(true);
    }
  };

  useEffect(() => {
    !showSearchIcon && toggleSearchIcon();
  }, [sticky]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("scroll", () => setShowSearchIcon(true));
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("scroll", () => setShowSearchIcon(true));
      !showSearchIcon && toggleSearchIcon();
    };
  });

  const toggleSearchIcon = () => setShowSearchIcon(!showSearchIcon);

  const menuItemsObject =
    domainMenuItemsObj.length > 0
      ? {
          homePageMenuItems: domainMenuItemsObj.find((item) => item.title === "home" || item.title === "Home"),
          hamburgerMenuItems: domainMenuItemsObj.find((item) => item.title === "hamburger"),
        }
      : null;

  const homePageMenuItems = get(menuItemsObject, ["homePageMenuItems", "children"], []);

  useEffect(() => {
    if (loading) headerDown(true);
  }, [loading]);

  return (
    <div styleName="base">
      <Headroom onPin={() => headerDown(true)} onUnpin={() => headerDown(false)} onUnfix={() => headerDown(true)}>
        <div styleName="header-wrapper" className="logo-ham-search">
          <div styleName="hamburger-search-wrapper" className="hamburger-search-wrapper">
            <div styleName="search-ham" className="search-ham">
              <HamburgerIcon onMenuToggle={toggleVerticalMenubar} isNavbarOpen={isVerticalMenubar} />
              <>
                {showSearchIcon && (
                  <span className="search-icon-wrapper" styleName="search-icon" onClick={toggleSearchIcon}>
                    <SearchIcon color={domainStyle.searchColor} />
                  </span>
                )}
                <span ref={ref} styleName={!showSearchIcon ? "navbar-search" : ""}>
                  {!showSearchIcon && <NavbarSearch lang="bn" />}
                </span>
              </>
            </div>
            <time styleName={`date`}>{getDateFromUtils("bn")}</time>
          </div>
          <div styleName={`logo ${isVerticalMenubar ? "vertical-menu-bar-logo" : ""}`}>
            <DesktopLogo domainSlug={domainSlug} />
          </div>
          <div styleName="login-wrapper" className="print-logo-wrapper">
            <div styleName={`login-button-wrapper ${domainSlug === "trust" ? "trust-donate-wrapper" : ""}`}>
              <LoginContainer
                loginBorderStyle={domainStyle.loginBorder}
                loginTextStyle={domainStyle.loginText}
                donateButtonLink={donateButtonLink}
                domainSlug={domainSlug}
              />
            </div>
          </div>
        </div>
        <div styleName="navbar" className="navbar-wrapper">
          <Navbar menu={homePageMenuItems.slice(0, 12)} />
        </div>
      </Headroom>
    </div>
  );
};

StaticHeader.propTypes = {
  toggleVerticalMenubar: func,
  isVerticalMenubar: bool,
  domainSlug: string,
  domainMenuItemsObj: array,
  donateButtonLink: string,
  headerDown: func,
  loading: bool,
};

const mapStateToProps = (state) => ({
  loading: state.pageLoading,
  donateButtonLink: get(state, ["qt", "config", "publisher-attributes", "trust-donate"], ""),
  domainMenuItemsObj: get(state, ["qt", "data", "navigationMenu", "menuItems"], []),
});

const mapDispatchToProps = (dispatch) => ({
  headerDown: (flag) => dispatch({ type: "HEADER_DOWN", payload: flag }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaticHeader);
