import React from "react";
import { array, string } from "prop-types";
import { Link } from "@quintype/components";
import { connect } from "react-redux";
import { get } from "lodash";

import Anchor from "../../atoms/Anchor";

import "./footerMenuGroup.m.css";

const FooterMenuGroup = ({ links, className = "", currentHostUrl }) => {
  const footerMenuItem = (item, index) => {
    const { "item-type": itemType, title, completeUrl } = item;

    if (itemType === "placeholder") {
      return (
        <span key={index} styleName="footer-menu-item" className="footer-list-menu-item">
          {title}
        </span>
      );
    }

    if (String(completeUrl).includes(currentHostUrl)) {
      let url;

      if (itemType === "section") {
        url = `/${item["section-slug"]}`;
      } else if (itemType === "tag" || itemType === "entity") {
        const slugType = `${itemType}-slug`;
        url = `/topic/${item[slugType]}`;
      }

      return url ? (
        <Link key={index} aria-label={title} href={url} styleName="footer-menu-item" className="footer-list-menu-item">
          {title}
        </Link>
      ) : (
        <a
          key={index}
          target="_self"
          aria-label={title}
          href={completeUrl}
          styleName="footer-menu-item"
          className="footer-list-menu-item"
        >
          {title}
        </a>
      );
    }

    return (
      <Anchor
        key={index}
        aria-label={title}
        href={completeUrl}
        styleName="footer-menu-item"
        className="footer-list-menu-item"
        disableAjaxLinks={true}
      >
        {title}
      </Anchor>
    );
  };

  return (
    <div styleName="wrapper" className={`footer-menu-group ${className}`}>
      {links.map((item, index) => footerMenuItem(item, index))}
    </div>
  );
};

FooterMenuGroup.propTypes = {
  links: array,
  className: string,
  currentHostUrl: string,
};

const mapStateToProps = (state) => ({
  currentHostUrl: get(state, ["qt", "currentHostUrl"]),
});

export default connect(mapStateToProps)(FooterMenuGroup);
