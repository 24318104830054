// Action to enable/disable to loading indicator
export function setLoading(isLoading) {
  return isLoading ? { type: "QT-PAGE_LOADING" } : { type: "QT-PAGE_FINISHED_LOADING" };
}

export function setVerticalMenuBar(flag) {
  return {
    type: "VERTICAL_MENU_BAR",
    payload: flag,
  };
}

export function setMobileFilter(flag) {
  return {
    type: "MOBILE_FILTER",
    payload: flag,
  };
}

export function setMetypeBar(flag) {
  return {
    type: "METYPE_MENU_BAR",
    payload: flag,
  };
}

export function setTopAndBottomAd(flag) {
  return {
    type: "TOP_AND_BOTTOM_AD_REDUCER",
    payload: flag,
  };
}

export function breakingNewsAd(flag) {
  return {
    type: "BREAKING_NEWS_AD",
    payload: flag,
  };
}

export function setBookmark(bookmark) {
  return {
    type: "BOOKMARK",
    payload: bookmark,
  };
}

// Action to store all the authors in redux
export function setAuthors(authors) {
  return {
    type: "SET_AUTHORS",
    payload: authors,
  };
}

// Action to store all the authors in redux
export function setHomeComments(list) {
  return {
    type: "SET_HOME_COMMENTS",
    payload: list,
  };
}

export function setMember(member) {
  return {
    type: "QT-MEMBER_UPDATED",
    member,
  };
}

// set the jwt token
export function setJwtToken(token) {
  return {
    type: "METYPE_JWT_TOKEN",
    payload: token,
  };
}

export function setCurrentPath(path) {
  return {
    type: "CURRENT_PATH",
    payload: path,
  };
}

export function clearUserInfo() {
  return { type: "CLEAR_ALL" };
}

export function setSocialShare(flag) {
  return {
    type: "SET_SOCIAL_SHARE",
    payload: flag,
  };
}
