import React from "react";
import { bool } from "prop-types";

const PlayPauseButton = ({ isPlaying = true }) => {
  return (
    <svg width="42" height="42" viewBox="0 0 24 24">
      <circle opacity="0.6" cx="12" cy="12" r="10" />
      {isPlaying ? (
        <path
          fill="#FFFFFF"
          opacity="0.87"
          d="M10.078,15.87c-0.235,0.161-0.473,0.172-0.714,0.033C9.121,15.764,9,15.555,9,15.276V8.724
c0-0.279,0.121-0.488,0.363-0.627c0.242-0.139,0.48-0.128,0.714,0.033l5.145,3.276c0.22,0.147,0.33,0.345,0.33,0.594
s-0.11,0.447-0.33,0.594L10.078,15.87z"
        />
      ) : (
        <path fill="#FFFFFF" opacity="0.87" d="M12.607,15V9h2.061v6H12.607z M9,15V9h2.061v6H9z" />
      )}
    </svg>
  );
};

PlayPauseButton.propTypes = {
  isPlaying: bool,
};

export default PlayPauseButton;
