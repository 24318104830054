import React from "react";
import { string } from "prop-types";

const Youtube = ({ height = "28", width = "28" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      fill="#FF0000"
      d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
    />
    <path
      fill="#FFFFFF"
      d="M25.543,10.498C26,12.28,26,16,26,16s0,3.72-0.457,5.502c-0.254,0.985-0.997,1.76-1.938,2.022
	C21.896,24,16,24,16,24s-5.893,0-7.605-0.476c-0.945-0.266-1.687-1.04-1.938-2.022C6,19.72,6,16,6,16s0-3.72,0.457-5.502
	c0.254-0.985,0.997-1.76,1.938-2.022C10.107,8,16,8,16,8s5.896,0,7.605,0.476C24.55,8.742,25.292,9.516,25.543,10.498L25.543,10.498
	z M14,19.5l6-3.5l-6-3.5V19.5z"
    />
  </svg>
);

Youtube.propTypes = {
  height: string,
  width: string,
};

export default Youtube;
