import React, { memo, useCallback, useEffect, useState } from "react";
import { array, arrayOf, bool, func, object, string } from "prop-types";
import { connect, useDispatch } from "react-redux";
import get from "lodash/get";

import DesktopLogo from "../../../../atoms/DesktopLogo";
import Headroom from "../Headroom";
import { SvgIcon } from "../../../../icons";
import Navbar from "../../Navbar";
import LanguageEdition from "../../../../atoms/LanguageEdition";
import Epaper from "../../../../atoms/Epaper";
import NavSearch from "../../../../atoms/NavSearch";
import HamburgerMenu from "../../HamburgerMenu";
import PaloAuth from "../../../../molecules/PaloAuth";
import HeaderNews from "../../../../molecules/HeaderNews";

import { useDeviceType } from "../../../../utils/react-utils";
import { getHeaderCollection } from "../../../../helper/api";

import "./palo-header.m.css";

const PaloHeader = ({ publisherAttributes, menus = [], domainSlug, lang, headerApi, headerDown, loading }) => {
  const navMenus = menus.find(({ title }) => title === "home" || title === "Home")?.children || [];
  const hamburgerMenus = menus.find(({ title = "" }) => title.match(/advanced hamburger/gim));
  const footerMenu = menus.find(({ title }) => title?.match(/footer bottom/gim));

  const [showHam, setShowHam] = useState(false);
  const dispatch = useDispatch();

  const closeCallback = useCallback(() => {
    setShowHam((prev) => !prev);
  }, []);

  useEffect(() => {
    getHeaderCollection(headerApi)
      .then((res) => {
        dispatch({
          type: "HEADER_STORIES",
          payload: res.items || [],
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const { tab, desktop } = useDeviceType();

  useEffect(() => {
    if (loading) headerDown(true);
  }, [loading]);

  return (
    <>
      <div styleName="base">
        <Headroom
          desktop={desktop || tab}
          desktopHeight={tab ? 96 : desktop ? 116 : 0}
          onPin={() => headerDown(true)}
          onUnpin={() => headerDown(false)}
          onUnfix={() => headerDown(true)}
        >
          <div styleName="head">
            <DesktopLogo styleName="logo" domainSlug={domainSlug} lang={publisherAttributes.lang} />
            {(tab || desktop) && <HeaderNews />}

            <div styleName="top-right_mobile">
              <NavSearch showText={false} />
              <div styleName="bar" />
              <div styleName="login-wrapper">
                <PaloAuth />
              </div>
            </div>
          </div>

          <div styleName="nav-container">
            <div styleName="navbar" id="navbar">
              <div styleName="nav-menu">
                <Navbar menu={navMenus.slice(0, 11)} />
              </div>

              <div styleName="version-ham">
                <NavSearch lang={lang} />
                <Epaper styleName="epaper" />

                <LanguageEdition publisherAttributes={publisherAttributes} styleName="edition" />
                <div styleName="login-wrapper login-desktop">
                  <PaloAuth />
                </div>

                <div styleName="hamburger-container">
                  <SvgIcon type="hamburger" styleName="hamburger" onClick={closeCallback} />
                </div>
              </div>
            </div>
          </div>
        </Headroom>
      </div>

      {showHam && (
        <HamburgerMenu
          menu={hamburgerMenus}
          lang={lang}
          onClose={closeCallback}
          publisherAttributes={publisherAttributes}
          footerMenu={footerMenu}
        />
      )}
    </>
  );
};

PaloHeader.propTypes = {
  domainSlug: string,
  publisherAttributes: object,
  menus: array,
  stories: arrayOf(object),
  lang: string,
  headerDown: func,
  loading: bool,
  headerApi: string,
};

const mapStateToProps = (state) => ({
  loading: state.pageLoading,
  publisherAttributes: get(state, ["qt", "config", "publisher-attributes"], {}),
  headerApi: get(state, ["qt", "config", "publisher-attributes", "header_collection_slug"]),
  menus: get(state, ["qt", "data", "navigationMenu", "menuItems"], []),
});

const mapDispatchToProps = (dispatch) => ({
  headerDown: (flag) => dispatch({ type: "HEADER_DOWN", payload: flag }),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(PaloHeader));
