import React from "react";
import { arrayOf, func, object } from "prop-types";

import HamItem from "../HamItem";
import { SvgIcon } from "../../../../icons";

import "./ham-table.m.css";

const HamTable = ({ menus = [], click }) => {
  if (!menus.length) return null;

  let timeoutId;

  const openClose = (iconIndex) => {
    clearTimeout(timeoutId);
    const element = document.querySelector(`.${iconIndex}`);
    const active = element.classList.contains("gray-open");
    const tr = element.closest("tr");
    const td = tr.querySelector(".child-root");
    const child = td.querySelector(".child-row");
    const { height } = child.getBoundingClientRect();

    if (active) {
      element.classList.remove("gray-open");
      td.style.height = 0;
    } else {
      element.classList.add("gray-open");
      td.style.height = height + 1 + "px";
    }

    return false;
  };

  return (
    <div styleName="base">
      <table styleName="table">
        {menus.map((item, index) => (
          <tr styleName="row" key={index}>
            <td styleName="parent" className="parent-container">
              <HamItem menu={item} click={click} styleName="parent-item" />
            </td>
            <td styleName="row-icon">
              <button styleName="arrow-button" onClick={() => openClose(`arrow-${index + 1}`)}>
                <SvgIcon
                  type="arrow"
                  className={`arrow-${index + 1}`}
                  styleName={`arrow ${item["item-type"] === "placeholder" ? "placeholder" : ""} `}
                />
              </button>
            </td>
            <td className="child-root" styleName="child">
              <div styleName="row-child" className="child-row">
                {item.children.map((childItem, childIndex) => (
                  <HamItem key={childIndex} click={click} menu={childItem} styleName="child-item" />
                ))}
              </div>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

HamTable.propTypes = {
  menus: arrayOf(object),
  click: func,
};

export default HamTable;
