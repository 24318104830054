import React from "react";

import { string } from "prop-types";

const Twitter = ({ height = "28", width = "28" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    height={height}
    width={width}
  >
    <circle fill="#0F1419" cx="12" cy="12" r="12" />
    <path
      fill="#FFFFFF"
      d="M15.531,7h1.662l-3.63,4.236L17.833,17h-3.343l-2.62-3.495L8.876,17H7.212l3.882-4.531L7,7h3.427
l2.366,3.195L15.531,7z M14.947,15.986h0.92L9.926,7.962H8.937L14.947,15.986z"
    />
  </svg>
);

Twitter.propTypes = {
  height: string,
  width: string,
};

const Facebook = ({ height = "28", width = "28" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      fill="#1877F2"
      d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
    />
    <path
      fill="#FFFFFF"
      d="M18,17.5h2.5l1-4H18v-2c0-1.03,0-2,2-2h1.5V6.14C21.174,6.097,19.943,6,18.643,6C15.928,6,14,7.657,14,10.7
	v2.8h-3v4h3V26h4V17.5z"
    />
  </svg>
);

Facebook.propTypes = {
  height: string,
  width: string,
};

const Share = ({ height = "28", width = "28" }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      fill="#FFCDD2"
      d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
    />
    <path
      fill="#D60000"
      d="M15.75,18.625H14c-1.435,0-2.844,0.391-4.072,1.133c-1.229,0.742-2.231,1.805-2.9,3.076
	C7.009,22.598,7,22.361,7,22.125c0-4.833,3.917-8.75,8.75-8.75V9l8.75,7l-8.75,7V18.625z"
    />
  </svg>
);

Share.propTypes = {
  height: string,
  width: string,
};

const FontIncrease = ({ lang, height = "28", width = "28", bgColor = "#3681CF", fontColor = "#FFFFFF" }) =>
  lang === "en" ? (
    <svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve"
    >
      <path
        fill={bgColor}
        d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
      />
      <path
        fill={fontColor}
        d="M12.288,13.16L9.388,22H7.051l3.962-10.664H12.5L12.288,13.16z M14.698,22l-2.908-8.84l-0.234-1.824h1.501
	L17.042,22H14.698z M14.566,18.03v1.721H8.934V18.03H14.566z M24.798,16.06v1.838h-7.31V16.06H24.798z M22.146,13.167v7.764h-2
	v-7.764H22.146z"
      />
    </svg>
  ) : (
    <svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve"
    >
      <path
        fill={bgColor}
        d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
      />
      <path
        fill={fontColor}
        d="M5,11.342L5.196,11h12.962l0.791,0.791l-0.245,0.391H17.71v1.296c0,0.359,0.001,0.731,0.004,1.117
	c0.003,0.386,0.004,0.766,0.004,1.141s0.001,0.734,0.004,1.076c0.003,0.342,0.004,0.65,0.004,0.921c0,0.337,0.001,0.671,0.004,1.003
	c0.003,0.332,0.007,0.645,0.012,0.942c0.005,0.296,0.014,0.565,0.024,0.807c0.011,0.242,0.025,0.441,0.041,0.599L17.213,21.5
	c-0.043-0.038-0.111-0.098-0.204-0.179c-0.093-0.081-0.187-0.166-0.285-0.253c-0.097-0.087-0.19-0.168-0.277-0.245
	c-0.087-0.076-0.15-0.13-0.188-0.163v-0.016c-0.19-0.473-0.448-0.876-0.774-1.211c-0.326-0.334-0.669-0.616-1.027-0.844
	c-0.332,0.386-0.722,0.682-1.17,0.889c-0.448,0.206-0.923,0.31-1.423,0.31c-0.364,0-0.733-0.058-1.104-0.175
	c-0.373-0.117-0.737-0.298-1.092-0.542c-0.356-0.245-0.698-0.559-1.027-0.942c-0.329-0.383-0.632-0.838-0.909-1.365
	c-0.174-0.326-0.333-0.665-0.477-1.015c-0.144-0.351-0.273-0.689-0.387-1.015c-0.114-0.326-0.215-0.63-0.302-0.913
	c-0.087-0.283-0.16-0.522-0.22-0.717c0.071-0.043,0.173-0.09,0.306-0.139c0.133-0.049,0.235-0.079,0.306-0.09
	c0.076,0.147,0.176,0.329,0.302,0.546c0.125,0.217,0.264,0.448,0.416,0.693c0.19,0.625,0.432,1.172,0.726,1.639
	c0.293,0.468,0.616,0.856,0.966,1.166c0.35,0.31,0.718,0.542,1.101,0.697c0.383,0.155,0.757,0.232,1.121,0.232
	c0.619,0,1.16-0.209,1.622-0.628c0.201-0.185,0.353-0.394,0.456-0.628c0.103-0.234,0.171-0.462,0.204-0.685
	c0.033-0.223,0.038-0.429,0.016-0.62c-0.022-0.19-0.054-0.337-0.098-0.44c-0.12-0.141-0.266-0.247-0.44-0.318
	c-0.174-0.071-0.353-0.106-0.538-0.106c-0.054,0-0.093,0.003-0.114,0.008c0.005,0.016,0.008,0.057,0.008,0.122
	c0,0.196-0.038,0.383-0.114,0.562c-0.076,0.179-0.182,0.336-0.318,0.469c-0.136,0.133-0.3,0.24-0.493,0.322
	c-0.193,0.082-0.406,0.122-0.64,0.122c-0.206,0-0.403-0.043-0.591-0.13c-0.188-0.087-0.352-0.204-0.493-0.351
	c-0.142-0.147-0.253-0.319-0.334-0.518C9.64,14.833,9.6,14.622,9.6,14.399v-0.008c0-0.255,0.049-0.484,0.147-0.685
	c0.098-0.201,0.232-0.372,0.403-0.514c0.171-0.141,0.375-0.25,0.611-0.326c0.236-0.076,0.496-0.117,0.778-0.122
	c0.185,0,0.397,0.02,0.636,0.061c0.239,0.041,0.488,0.117,0.746,0.228c0.258,0.112,0.516,0.265,0.774,0.461
	c0.258,0.196,0.499,0.448,0.721,0.758c0.359,0.495,0.586,0.996,0.681,1.504c0.095,0.508,0.102,0.985,0.02,1.431
	c0.119,0.163,0.233,0.337,0.342,0.522c0.109,0.185,0.212,0.37,0.31,0.555c0.098,0.185,0.188,0.366,0.269,0.542
	c0.081,0.177,0.152,0.341,0.212,0.493c-0.006,0,0.014-0.006,0.057-0.017c-0.027-0.141-0.049-0.304-0.065-0.489
	c-0.016-0.185-0.024-0.399-0.024-0.644v-1.72c0-0.603-0.001-1.177-0.004-1.72c-0.003-0.543-0.004-1.038-0.004-1.484v-1.043H5.758
	L5,11.342z"
      />
      <path
        fill={fontColor}
        d="M19.796,16.511c0.054-0.087,0.115-0.181,0.183-0.281c0.068-0.101,0.14-0.197,0.216-0.289h0.929
	c0.201,0,0.418-0.001,0.652-0.004c0.233-0.003,0.473-0.007,0.718-0.012c0-0.359,0.003-0.696,0.008-1.011
	c0.006-0.315,0.008-0.587,0.008-0.815v-0.375c0.087-0.065,0.186-0.133,0.298-0.204c0.111-0.071,0.213-0.136,0.306-0.196
	c0.054-0.033,0.109-0.065,0.163-0.098c0.054-0.033,0.111-0.068,0.171-0.106l0.432,0.277c-0.006,0.12-0.008,0.242-0.008,0.367
	c0,0.125,0,0.255,0,0.391c-0.006,0.234-0.008,0.501-0.008,0.803c0,0.302-0.003,0.618-0.008,0.95c0.343-0.005,0.667-0.01,0.974-0.012
	c0.307-0.003,0.578-0.007,0.811-0.012c0.136-0.005,0.268-0.008,0.395-0.008c0.128,0,0.251,0,0.371,0l0.31,0.407l-0.179,0.31
	c-0.054,0.092-0.114,0.189-0.179,0.289c-0.065,0.101-0.13,0.197-0.196,0.289c-0.206,0-0.519,0.006-0.937,0.016
	c-0.419,0.011-0.878,0.016-1.378,0.016c0,0.244-0.002,0.481-0.004,0.709c-0.003,0.228-0.005,0.441-0.008,0.64
	c-0.003,0.198-0.006,0.375-0.008,0.53c-0.003,0.155-0.004,0.281-0.004,0.379c-0.093,0.071-0.193,0.139-0.302,0.204
	c-0.109,0.065-0.209,0.125-0.301,0.179c-0.054,0.027-0.109,0.057-0.163,0.09c-0.054,0.033-0.111,0.065-0.171,0.098l-0.432-0.277
	c0-0.119,0-0.243,0-0.371c0-0.128,0.003-0.26,0.008-0.395c0.005-0.234,0.008-0.502,0.008-0.803c0-0.302,0.003-0.624,0.008-0.966
	c-0.342,0.006-0.667,0.01-0.974,0.012c-0.307,0.003-0.583,0.004-0.827,0.004c-0.142,0.005-0.276,0.008-0.404,0.008
	c-0.128,0-0.251,0-0.371,0l-0.285-0.44L19.796,16.511z"
      />
    </svg>
  );

FontIncrease.propTypes = {
  lang: string,
  height: string,
  width: string,
  bgColor: string,
  fontColor: string,
};

const FontDecrease = ({ lang, height = "28", width = "28", bgColor = "#3681CF", fontColor = "#FFFFFF" }) =>
  lang === "en" ? (
    <svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve"
    >
      <path
        fill={bgColor}
        d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
      />
      <path
        fill={fontColor}
        d="M13.288,13.16l-2.9,8.84H8.051l3.962-10.664H13.5L13.288,13.16z M15.698,22l-2.908-8.84l-0.234-1.824h1.501
	L18.042,22H15.698z M15.566,18.03v1.721H9.934V18.03H15.566z M23.059,16.595v1.655H18.92v-1.655H23.059z"
      />
    </svg>
  ) : (
    <svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve"
    >
      <path
        fill={bgColor}
        d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
      />
      <path
        fill={fontColor}
        d="M5,11.342L5.196,11h12.962l0.791,0.791l-0.245,0.391H17.71v1.296c0,0.359,0.001,0.731,0.004,1.117
	c0.003,0.386,0.004,0.766,0.004,1.141s0.001,0.734,0.004,1.076c0.003,0.342,0.004,0.65,0.004,0.921c0,0.337,0.001,0.671,0.004,1.003
	c0.003,0.332,0.007,0.645,0.012,0.942c0.005,0.296,0.014,0.565,0.024,0.807c0.011,0.242,0.025,0.441,0.041,0.599L17.213,21.5
	c-0.043-0.038-0.111-0.098-0.204-0.179c-0.093-0.081-0.187-0.166-0.285-0.253c-0.097-0.087-0.19-0.168-0.277-0.245
	c-0.087-0.076-0.15-0.13-0.188-0.163v-0.016c-0.19-0.473-0.448-0.876-0.774-1.211c-0.326-0.334-0.669-0.616-1.027-0.844
	c-0.332,0.386-0.722,0.682-1.17,0.889c-0.448,0.206-0.923,0.31-1.423,0.31c-0.364,0-0.733-0.058-1.104-0.175
	c-0.373-0.117-0.737-0.298-1.092-0.542c-0.356-0.245-0.698-0.559-1.027-0.942c-0.329-0.383-0.632-0.838-0.909-1.365
	c-0.174-0.326-0.333-0.665-0.477-1.015c-0.144-0.351-0.273-0.689-0.387-1.015c-0.114-0.326-0.215-0.63-0.302-0.913
	c-0.087-0.283-0.16-0.522-0.22-0.717c0.071-0.043,0.173-0.09,0.306-0.139c0.133-0.049,0.235-0.079,0.306-0.09
	c0.076,0.147,0.176,0.329,0.302,0.546c0.125,0.217,0.264,0.448,0.416,0.693c0.19,0.625,0.432,1.172,0.726,1.639
	c0.293,0.468,0.616,0.856,0.966,1.166c0.35,0.31,0.718,0.542,1.101,0.697c0.383,0.155,0.757,0.232,1.121,0.232
	c0.619,0,1.16-0.209,1.622-0.628c0.201-0.185,0.353-0.394,0.456-0.628c0.103-0.234,0.171-0.462,0.204-0.685
	c0.033-0.223,0.038-0.429,0.016-0.62c-0.022-0.19-0.054-0.337-0.098-0.44c-0.12-0.141-0.266-0.247-0.44-0.318
	c-0.174-0.071-0.353-0.106-0.538-0.106c-0.054,0-0.093,0.003-0.114,0.008c0.005,0.016,0.008,0.057,0.008,0.122
	c0,0.196-0.038,0.383-0.114,0.562c-0.076,0.179-0.182,0.336-0.318,0.469c-0.136,0.133-0.3,0.24-0.493,0.322
	c-0.193,0.082-0.406,0.122-0.64,0.122c-0.206,0-0.403-0.043-0.591-0.13c-0.188-0.087-0.352-0.204-0.493-0.351
	c-0.142-0.147-0.253-0.319-0.334-0.518C9.64,14.833,9.6,14.622,9.6,14.399v-0.008c0-0.255,0.049-0.484,0.147-0.685
	c0.098-0.201,0.232-0.372,0.403-0.514c0.171-0.141,0.375-0.25,0.611-0.326c0.236-0.076,0.496-0.117,0.778-0.122
	c0.185,0,0.397,0.02,0.636,0.061c0.239,0.041,0.488,0.117,0.746,0.228c0.258,0.112,0.516,0.265,0.774,0.461
	c0.258,0.196,0.499,0.448,0.721,0.758c0.359,0.495,0.586,0.996,0.681,1.504c0.095,0.508,0.102,0.985,0.02,1.431
	c0.119,0.163,0.233,0.337,0.342,0.522c0.109,0.185,0.212,0.37,0.31,0.555c0.098,0.185,0.188,0.366,0.269,0.542
	c0.081,0.177,0.152,0.341,0.212,0.493c-0.006,0,0.014-0.006,0.057-0.017c-0.027-0.141-0.049-0.304-0.065-0.489
	c-0.016-0.185-0.024-0.399-0.024-0.644v-1.72c0-0.603-0.001-1.177-0.004-1.72c-0.003-0.543-0.004-1.038-0.004-1.484v-1.043H5.758
	L5,11.342z"
      />
      <path
        fill={fontColor}
        d="M20.307,15.871c0.163,0,0.391-0.002,0.685-0.004c0.294-0.003,0.624-0.005,0.99-0.008
	c0.367-0.003,0.755-0.007,1.166-0.012c0.41-0.006,0.82-0.011,1.227-0.017c0.957-0.006,1.984-0.014,3.082-0.024l0.286,0.359
	c-0.044,0.06-0.087,0.117-0.13,0.171c-0.044,0.054-0.087,0.109-0.13,0.163c-0.076,0.092-0.156,0.192-0.24,0.298
	c-0.085,0.106-0.164,0.2-0.241,0.281c-0.163,0-0.391,0.001-0.685,0.004c-0.293,0.003-0.622,0.006-0.986,0.008
	c-0.364,0.003-0.753,0.007-1.166,0.012c-0.413,0.005-0.821,0.011-1.223,0.016c-0.478,0.005-0.972,0.011-1.48,0.017
	c-0.508,0.005-1.039,0.011-1.594,0.016l-0.277-0.367c0.108-0.158,0.221-0.314,0.338-0.469
	C20.045,16.161,20.171,16.012,20.307,15.871z"
      />
    </svg>
  );

FontDecrease.propTypes = {
  lang: string,
  height: string,
  width: string,
  bgColor: string,
  fontColor: string,
};

const CommentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
    <path
      d="M14 0H2a2 2 0 00-2 2v9a2 2 0 002 2h3v2.625a.374.374 0 00.375.375.36.36 0 00.222-.075L9.5 13H14a2 2 0 002-2V2a2 2 0 00-2-2zm.5 11a.5.5 0 01-.5.5H9l-.4.3-2.1 1.575V11.5H2a.5.5 0 01-.5-.5V2a.5.5 0 01.5-.5h12a.5.5 0 01.5.5z"
      data-name="Path 502"
      fill="#121212"
    />
  </svg>
);

const Bookmark = ({ height = "28", width = "28" }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      fill="#E0E0E0"
      d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
    />
    <path
      fill="#2B2B2B"
      d="M9.778,8h12.444c0.206,0,0.404,0.082,0.55,0.228C22.918,8.374,23,8.571,23,8.778V23.77
	c0,0.065-0.016,0.129-0.047,0.186c-0.031,0.057-0.076,0.106-0.13,0.141c-0.055,0.035-0.117,0.056-0.182,0.062
	c-0.065,0.005-0.13-0.006-0.189-0.033L16,21.246l-6.452,2.879c-0.059,0.026-0.124,0.038-0.189,0.033
	c-0.065-0.005-0.127-0.026-0.181-0.061c-0.054-0.035-0.099-0.084-0.13-0.14C9.016,23.899,9,23.835,9,23.77V8.778
	c0-0.206,0.082-0.404,0.228-0.55C9.374,8.082,9.571,8,9.778,8L9.778,8z M21.444,21.973V9.556H10.556v12.417L16,19.543L21.444,21.973
	z M16,16.944l-2.286,1.202l0.436-2.545l-1.849-1.803l2.556-0.372L16,11.111l1.143,2.316l2.555,0.372l-1.849,1.803l0.436,2.545
	L16,16.944z"
    />
  </svg>
);
Bookmark.propTypes = {
  height: string,
  width: string,
};
const Email = ({ bgcolor = "#121212", color = "#ffffff" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g id="Group_3804" data-name="Group 3804" transform="translate(-179 -383)">
      <g id="Group_3682" data-name="Group 3682" transform="translate(-368 -131)">
        <circle
          id="Ellipse_3"
          cx="14"
          cy="14"
          r="14"
          className="cls-1"
          data-name="Ellipse 3"
          transform="translate(547 514)"
          fill={bgcolor}
        />
        <path
          id="Path_185"
          d="M13.735 67.467A.165.165 0 0 1 14 67.6v5.592a1.313 1.313 0 0 1-1.312 1.308H1.313A1.313 1.313 0 0 1 0 73.188V67.6a.164.164 0 0 1 .265-.129c.612.476 1.425 1.08 4.214 3.106A4.892 4.892 0 0 0 7 71.878a4.894 4.894 0 0 0 2.524-1.3c2.789-2.028 3.598-2.635 4.211-3.111zM7 71c.634.011 1.548-.8 2.007-1.132 3.629-2.633 3.9-2.863 4.741-3.519a.654.654 0 0 0 .252-.517v-.52A1.313 1.313 0 0 0 12.688 64H1.313A1.313 1.313 0 0 0 0 65.313v.52a.658.658 0 0 0 .252.517c.837.654 1.113.886 4.741 3.519C5.452 70.2 6.366 71.011 7 71z"
          className="cls-2"
          data-name="Path 185"
          transform="translate(554 458)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);
Email.propTypes = {
  bgcolor: string,
  color: string,
};

const BookmarkActive = ({ height = "28", width = "28" }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      fill="#0573E6"
      d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
    />
    <path
      fill="#FFFFFF"
      d="M9.778,8h12.444c0.206,0,0.404,0.082,0.55,0.228C22.918,8.374,23,8.571,23,8.778V23.77
	c0,0.065-0.016,0.129-0.047,0.186c-0.031,0.057-0.076,0.106-0.13,0.141c-0.055,0.035-0.117,0.056-0.182,0.062
	c-0.065,0.005-0.13-0.006-0.189-0.033L16,21.246l-6.452,2.879c-0.059,0.026-0.124,0.038-0.189,0.033
	c-0.065-0.005-0.127-0.026-0.181-0.061c-0.054-0.035-0.099-0.084-0.13-0.14C9.016,23.899,9,23.835,9,23.77V8.778
	c0-0.206,0.082-0.404,0.228-0.55C9.374,8.082,9.571,8,9.778,8L9.778,8z M16,16.944l2.286,1.202l-0.436-2.545l1.849-1.803
	l-2.556-0.372L16,11.111l-1.143,2.316l-2.555,0.372l1.849,1.803l-0.436,2.545L16,16.944z"
    />
  </svg>
);

BookmarkActive.propTypes = {
  height: string,
  width: string,
};

const Print = ({ height = "28", width = "28" }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      fill="#595959"
      d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
    />
    <path
      fill="#FFFFFF"
      d="M12,20h8v4h-8V20z M21.6,22.4v-4H10.4v4H8.8c-0.212,0-0.416-0.084-0.566-0.234C8.084,22.016,8,21.812,8,21.6
	v-8c0-0.212,0.084-0.416,0.234-0.566C8.384,12.884,8.588,12.8,8.8,12.8h14.4c0.212,0,0.416,0.084,0.566,0.234
	C23.916,13.184,24,13.388,24,13.6v8c0,0.212-0.084,0.416-0.234,0.566c-0.15,0.15-0.353,0.234-0.566,0.234H21.6z M10.4,14.4V16h2.4
	v-1.6H10.4z M12,8h8c0.212,0,0.416,0.084,0.566,0.234C20.716,8.384,20.8,8.588,20.8,8.8v2.4h-9.6V8.8
	c0-0.212,0.084-0.416,0.234-0.566C11.584,8.084,11.788,8,12,8z"
    />
  </svg>
);
Print.propTypes = {
  height: string,
  width: string,
};

const CopyLink = ({ bgcolor = "#121212", color = "#ffffff" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g id="Group_3805" data-name="Group 3805" transform="translate(-179 -427)">
      <g id="Group_3675" data-name="Group 3675" transform="translate(-474 -24)">
        <g id="Group_3674" data-name="Group 3674">
          <circle
            id="Ellipse_3"
            cx="14"
            cy="14"
            r="14"
            className="cls-1"
            data-name="Ellipse 3"
            transform="translate(653 451)"
            fill={bgcolor}
          />
        </g>
        <path
          fill={color}
          id="Path_181"
          d="M8.93 5.069a4.154 4.154 0 0 1 .01 5.868l-.01.01-1.837 1.837a4.156 4.156 0 0 1-5.878-5.878L2.23 5.892a.437.437 0 0 1 .746.29 5.039 5.039 0 0 0 .265 1.442.44.44 0 0 1-.1.454l-.358.358a1.969 1.969 0 1 0 2.766 2.8L7.383 9.4a1.968 1.968 0 0 0 0-2.784 2.048 2.048 0 0 0-.283-.235.438.438 0 0 1-.19-.345 1.089 1.089 0 0 1 .32-.815l.576-.576a.439.439 0 0 1 .563-.045 4.167 4.167 0 0 1 .561.47zm3.854-3.854a4.161 4.161 0 0 0-5.878 0L5.069 3.052l-.01.01A4.157 4.157 0 0 0 5.63 9.4a.439.439 0 0 0 .563-.047l.576-.576a1.089 1.089 0 0 0 .32-.815.438.438 0 0 0-.19-.345 2.048 2.048 0 0 1-.283-.234 1.968 1.968 0 0 1 0-2.784l1.837-1.837a1.969 1.969 0 1 1 2.766 2.8l-.358.358a.44.44 0 0 0-.1.454 5.039 5.039 0 0 1 .265 1.442.437.437 0 0 0 .746.29l1.015-1.015a4.161 4.161 0 0 0 0-5.878z"
          className="cls-2"
          data-name="Path 181"
          transform="translate(660 458)"
        />
      </g>
    </g>
  </svg>
);
CopyLink.propTypes = {
  bgcolor: string,
  color: string,
};

const Instagram = ({ height = "28", width = "28" }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <radialGradient
      id="SVGID_1_"
      cx="-246.536"
      cy="264.8975"
      r="1"
      gradientTransform="matrix(1.941947e-15 -31.7144 -29.4969 -1.806164e-15 7822.1538 -7784.2769)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: "#FFDD55" }} />
      <stop offset="0.1" style={{ stopColor: "#FFDD55" }} />
      <stop offset="0.5" style={{ stopColor: "#FF543E" }} />
      <stop offset="1" style={{ stopColor: "#C837AB" }} />
    </radialGradient>
    <circle fill="url(#SVGID_1_)" cx="16" cy="16" r="16" />
    <radialGradient
      id="SVGID_2_"
      cx="-219.16"
      cy="276.2076"
      r="1"
      gradientTransform="matrix(2.7825 13.9007 57.2992 -11.4697 -15222.0215 6216.8076)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" style={{ stopColor: "#3771C8" }} />
      <stop offset="0.128" style={{ stopColor: "#3771C8" }} />
      <stop offset="1" style={{ stopColor: "#6600FF", stopOpacity: 0 }} />
    </radialGradient>

    <circle id="circle2" fill="url(#SVGID_2_)" cx="16" cy="16" r="16" />
    <path
      fill="#FFFFFF"
      d="M16.001,6c-2.716,0-3.057,0.012-4.123,0.06c-1.065,0.049-1.791,0.217-2.427,0.465
	C8.793,6.78,8.235,7.122,7.679,7.678C7.123,8.234,6.781,8.792,6.525,9.449c-0.248,0.636-0.417,1.363-0.465,2.427
	C6.012,12.943,6,13.284,6,16s0.012,3.056,0.06,4.122c0.049,1.065,0.217,1.791,0.465,2.427c0.256,0.658,0.597,1.216,1.153,1.771
	c0.556,0.556,1.114,0.899,1.771,1.154c0.636,0.247,1.363,0.416,2.428,0.465C12.943,25.988,13.284,26,16,26
	c2.716,0,3.056-0.012,4.123-0.06c1.065-0.049,1.792-0.217,2.428-0.465c0.657-0.255,1.215-0.598,1.77-1.154
	c0.556-0.556,0.898-1.114,1.154-1.771c0.246-0.636,0.415-1.363,0.465-2.427C25.987,19.056,26,18.716,26,16s-0.012-3.057-0.06-4.123
	c-0.05-1.065-0.219-1.791-0.465-2.427c-0.256-0.658-0.598-1.216-1.154-1.771c-0.556-0.556-1.113-0.898-1.771-1.153
	c-0.638-0.247-1.365-0.416-2.429-0.465C19.054,6.012,18.714,6,15.998,6H16.001z M15.104,7.802c0.266,0,0.563,0,0.897,0
	c2.67,0,2.987,0.01,4.041,0.057c0.975,0.045,1.504,0.207,1.857,0.344c0.467,0.181,0.799,0.398,1.149,0.748
	c0.35,0.35,0.567,0.683,0.748,1.15c0.137,0.352,0.3,0.881,0.344,1.856c0.048,1.054,0.058,1.371,0.058,4.04
	c0,2.669-0.01,2.985-0.058,4.04c-0.045,0.975-0.208,1.504-0.344,1.856c-0.181,0.467-0.398,0.799-0.748,1.149
	c-0.35,0.35-0.682,0.567-1.149,0.748c-0.352,0.138-0.882,0.3-1.857,0.345c-1.054,0.048-1.371,0.058-4.041,0.058
	c-2.67,0-2.987-0.01-4.041-0.058c-0.975-0.045-1.504-0.208-1.857-0.345c-0.467-0.181-0.8-0.398-1.15-0.748
	c-0.35-0.35-0.567-0.683-0.748-1.149c-0.137-0.352-0.3-0.881-0.344-1.856c-0.048-1.054-0.058-1.371-0.058-4.041
	s0.01-2.985,0.058-4.04c0.045-0.975,0.207-1.504,0.344-1.857c0.181-0.467,0.398-0.8,0.748-1.15c0.35-0.35,0.683-0.567,1.15-0.748
	c0.352-0.137,0.882-0.3,1.857-0.345c0.922-0.042,1.28-0.054,3.144-0.056V7.802z M21.339,9.462c-0.662,0-1.2,0.537-1.2,1.2
	c0,0.663,0.538,1.2,1.2,1.2c0.663,0,1.2-0.537,1.2-1.2C22.539,10,22.001,9.462,21.339,9.462L21.339,9.462z M16.001,10.865
	c-2.836,0-5.135,2.299-5.135,5.135s2.299,5.134,5.135,5.134c2.836,0,5.135-2.298,5.135-5.134S18.837,10.865,16.001,10.865
	L16.001,10.865z M16.001,12.667c1.841,0,3.333,1.492,3.333,3.333c0,1.841-1.493,3.333-3.333,3.333c-1.841,0-3.333-1.493-3.333-3.333
	C12.667,14.159,14.16,12.667,16.001,12.667L16.001,12.667z"
    />
  </svg>
);

Instagram.propTypes = {
  height: string,
  width: string,
};

const Linkedin = ({ height = "28", width = "28" }) => (
  <svg
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      fill="#0072B1"
      d="M16,0L16,0c8.837,0,16,7.163,16,16l0,0c0,8.837-7.163,16-16,16l0,0C7.163,32,0,24.837,0,16l0,0
	C0,7.163,7.163,0,16,0z"
    />
    <path
      fill="#FFFFFF"
      d="M11.333,9.667c0,0.442-0.176,0.866-0.489,1.178c-0.313,0.312-0.737,0.488-1.179,0.488
	c-0.442,0-0.866-0.176-1.178-0.489C8.175,10.532,8,10.108,8,9.666C8,9.224,8.176,8.8,8.489,8.488C8.801,8.175,9.225,8,9.667,8
	c0.442,0,0.866,0.176,1.178,0.489S11.333,9.225,11.333,9.667L11.333,9.667z M11.383,12.567H8.05V23h3.333V12.567z M16.649,12.567
	h-3.316V23h3.283v-5.475c0-3.05,3.975-3.333,3.975,0V23h3.291v-6.608c0-5.141-5.883-4.95-7.266-2.425L16.649,12.567L16.649,12.567z"
    />
  </svg>
);
Linkedin.propTypes = {
  height: string,
  width: string,
};

const PublisedTimeToggler = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="up-down" width="7.187" height="10" viewBox="0 0 7.187 10">
    <path
      id="Path_269"
      d="M17.539 62.754h5.978a.6.6 0 0 1 .427 1.03l-2.989 2.989a.6.6 0 0 1-.852 0l-2.992-2.989a.6.6 0 0 1 .428-1.03zm6.405-2.638l-2.989-2.989a.6.6 0 0 0-.852 0l-2.992 2.989a.6.6 0 0 0 .427 1.03h5.978a.6.6 0 0 0 .428-1.03z"
      data-name="Path 269"
      transform="translate(-16.934 -56.95)"
      fill="#00000061"
    ></path>
  </svg>
);

export {
  Twitter,
  Facebook,
  Share,
  FontIncrease,
  FontDecrease,
  Bookmark,
  BookmarkActive,
  Email,
  CopyLink,
  Print,
  Instagram,
  Linkedin,
  PublisedTimeToggler,
  CommentIcon,
};
