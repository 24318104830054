import React from "react";
import { string, func, bool, object } from "prop-types";

import Loadericon from "../../atoms/load-more-button-loader";

import "./button.m.css";

const Button = ({
  content,
  onClick,
  buttonType,
  isArrow,
  isOpen,
  className,
  isLoaderActive,
  buttonId,
  selectedItem,
  buttonStyle,
  textStyle,
}) => {
  const arrowView = () => (isOpen ? <i styleName="up" className="up" /> : <i styleName="down" className="down" />);

  return (
    <button styleName={buttonType} className={className} onClick={onClick} style={buttonStyle}>
      {isLoaderActive && buttonId === selectedItem ? (
        <Loadericon />
      ) : (
        <span className="content" style={textStyle}>
          {content}
        </span>
      )}
      {isArrow ? arrowView() : null}
    </button>
  );
};

Button.defaultProps = {
  buttonType: "type1",
  isOpen: false,
  isArrow: false,
  className: "button",
};

Button.propTypes = {
  content: string.isRequired,
  onClick: func,
  buttonType: string,
  isOpen: bool,
  isArrow: bool,
  className: string,
  isLoaderActive: bool,
  buttonId: string,
  selectedItem: string,
  buttonStyle: object,
  textStyle: object,
};

export default Button;
