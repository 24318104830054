import get from "lodash/get";
import { PAGE_TYPE } from "../../constants";

const getSectionSlug = (state, pageType) => {
  if (pageType === PAGE_TYPE.STORY_PAGE) {
    return get(state, ["data", "story", "sections", 0, "slug"], "NA");
  } else if (pageType === PAGE_TYPE.SECTION_PAGE) {
    return get(state, ["data", "section", "slug"], "NA");
  }
  return "NA";
};

const getCollectionSlug = (state, pageType) => {
  if (pageType === PAGE_TYPE.SECTION_PAGE) {
    return get(state, ["data", "section", "slug"], "NA");
  } else if (pageType === PAGE_TYPE.SEARCH_PAGE) {
    if (global.window) {
      const search = new URLSearchParams(global.window.location.search);
      return search.toString();
    }
    return "NA";
  } else if (pageType === PAGE_TYPE.TAG_PAGE) {
    return get(state, ["data", "tag", "slug"], "NA");
  } else if (pageType === PAGE_TYPE.AUTHOR_PAGE) {
    return get(state, ["data", "author", "author", "slug"], "NA");
  } else if (pageType === PAGE_TYPE.STORY_PAGE) {
    return get(state, ["data", "story", "sections", 0, "name"], "NA");
  } else if (pageType === PAGE_TYPE.COLLECTION_PAGE) {
    return get(state, ["data", "collection", "slug"], "NA");
  }

  return "DefaultCollection";
};

const getStoryId = (state, pageType, story) => {
  if (pageType === PAGE_TYPE.STORY_PAGE) {
    return story?.id || get(state, ["data", "story", "id"], "NA");
  }
  return "NA";
};

const getSectionList = (state, pageType, story) => {
  if (pageType === PAGE_TYPE.STORY_PAGE) {
    const sections = story?.sections || get(state, ["data", "story", "sections"]) || [];
    const sectionList = sections.map(({ name }) => name);
    return sectionList;
  }

  return [];
};

const getTagList = (state, pageType, story) => {
  if (pageType === PAGE_TYPE.STORY_PAGE) {
    const tags = story?.tags || get(state, ["data", "story", "tags"], []);
    const tagList = tags.map(({ name }) => name);
    return tagList;
  }

  return [];
};

const getStoryType = (state, pageType) => {
  if (pageType === PAGE_TYPE.STORY_PAGE) {
    return get(state, ["data", "story", "story-template"], "NA");
  }
  return "NA";
};

export const setViewportSizeMapping = (adSlot, googletag, viewPortSizeMapping = []) => {
  const mapping = googletag
    .sizeMapping()
    .addSize(viewPortSizeMapping[0].viewport, viewPortSizeMapping[0].sizes)
    .addSize(viewPortSizeMapping[1].viewport, viewPortSizeMapping[1].sizes)
    .addSize(viewPortSizeMapping[2].viewport, viewPortSizeMapping[2].sizes)
    .build();
  adSlot.defineSizeMapping(mapping);
};

export const generateLazyLoading = (googletag, fetchMarginPercent, renderMarginPercent, mobileScaling) => {
  const updateSlotStatus = (slotId, state) => {
    const elem = document.getElementById(slotId + "-" + state);
    if (elem) {
      elem.className = "activated";
      elem.innerText = "Yes";
    }
  };

  if (googletag && googletag.pubadsReady) {
    // detect whether PubAdsService is fully loaded
    googletag.pubads().enableLazyLoad({
      fetchMarginPercent, // Fetch slots within specified viewports
      renderMarginPercent, // Render slots within specified viewports
      mobileScaling, // Multiplies the specified value with the above values for mobile
    });

    googletag.pubads().addEventListener("slotRequested", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "fetched");
    });

    googletag.pubads().addEventListener("slotOnload", function (event) {
      updateSlotStatus(event.slot.getSlotElementId(), "rendered");
    });
  }
};

export const setTargetingParams = (googletag, adSlot, qtState, collectionSlug, layoutName, story) => {
  const pageType = qtState?.pageType;
  const environment = get(qtState, ["config", "publisher-attributes", "env"], "");
  const sectionSlug = getSectionSlug(qtState, pageType);
  const storyId = getStoryId(qtState, pageType, story);
  const sectionList = getSectionList(qtState, pageType, story);
  const tagList = getTagList(qtState, pageType, story);
  const collectionslug = collectionSlug?.replace("#", "") || getCollectionSlug(qtState, pageType);
  const storyType = getStoryType(qtState, pageType);
  const publisherName = qtState?.config?.domainSlug;

  adSlot
    .addService(googletag.pubads())
    .setTargeting("publisherId", "")
    .setTargeting("publisherName", publisherName || "prothomalo")
    .setTargeting("pageType", pageType)
    .setTargeting("environment", environment)
    .setTargeting("storyType", storyType)
    .setTargeting("section", sectionSlug)
    .setTargeting("storyId", storyId)
    .setTargeting("collectionSlug", collectionslug)
    .setTargeting("layoutName", layoutName || "DefaultLayout")
    .setTargeting("tagList", tagList)
    .setTargeting("sectionList", sectionList);
};

export function refreshAd(slot) {
  if (window.googletag && window.googletag.apiReady) {
    window.googletag.pubads().refresh(slot);
  }
}

export function destroySlot(slot) {
  if (window.googletag && window.googletag.apiReady) {
    window?.googletag?.destroySlots([slot]);
  }
}

export function checkSlot(id) {
  if (window.googletag && window.googletag.apiReady) {
    const slot = window.googletag
      .pubads()
      .getSlots()
      .find((item) => id === item.getSlotElementId());
    slot && destroySlot(slot);
  }
}

export const useDfpSlot = ({
  path,
  size,
  id,
  qtState,
  viewPortSizeMapping,
  renderOutOfThePage,
  collectionSlug,
  layoutName,
  targetStory,
}) => {
  const networkId = get(qtState, ["config", "ads-config", "network_id"]);

  let responsiveAdSlot;

  checkSlot(id);

  window.googletag.cmd.push(function () {
    if (renderOutOfThePage) {
      responsiveAdSlot = window.googletag.defineOutOfPageSlot(`${networkId}/${path}`, id);
    } else {
      responsiveAdSlot = window.googletag.defineSlot(`${networkId}/${path}`, size, id);
    }
    const {
      enable_lazy_load_ads: enableLazyLoadAds,
      fetch_margin_percent: fetchMarginPercent = 0,
      render_margin_percent: renderMarginPercent = 0,
      mobile_scaling: mobileScaling = 0,
    } = get(qtState, ["config", "ads-config", "dfp_ads"], {});

    if (responsiveAdSlot) {
      setTargetingParams(window.googletag, responsiveAdSlot, qtState, collectionSlug, layoutName, targetStory);

      if (viewPortSizeMapping) {
        setViewportSizeMapping(responsiveAdSlot, window.googletag, viewPortSizeMapping);
      }

      if (enableLazyLoadAds)
        generateLazyLoading(window.googletag, fetchMarginPercent, renderMarginPercent, mobileScaling);

      window.googletag.enableServices();
    }
  });

  window.googletag.cmd.push(function () {
    window.googletag.display(id);
  });

  return responsiveAdSlot;
};

export const getAdSlots = ({
  path,
  size,
  id,
  qtState,
  type,
  viewPortSizeMapping,
  layoutName,
  renderOutOfThePage,
  refreshAdUnit,
  collectionSlug,
  targetStory,
}) => {
  return useDfpSlot({
    path,
    size,
    id,
    qtState,
    type,
    viewPortSizeMapping,
    layoutName,
    refreshAdUnit,
    renderOutOfThePage,
    collectionSlug,
    targetStory,
  });
};
