import React, { useEffect, useState } from "react";
import { array, bool, func, number, string } from "prop-types";
import { BREAKING_NEWS_UPDATED, SocialShare } from "@quintype/components";
import { connect } from "react-redux";
import get from "lodash/get";

import CloseIcon from "../atoms/icons/close";
import ShareIcon from "../atoms/icons/Share";
import ShareAllTemplate from "../molecules/ShareAllTemplate";
import ScrollSnap from "../atoms/ScrollSnap";
import RightArrowButton from "../atoms/icons/right-arrow-button";
import LeftArrowButton from "../atoms/icons/left-arrow-button";
import Anchor from "../atoms/Anchor";

import { getBreakingNews } from "../helper/api";
import { DOMAIN_SLUG_NOT_BREAKING_NEWS, PAGE_TYPE_NOT_HEADER_FOOTER, PREVIEW_PAGE_TYPES } from "../../constants";
import { checkRender } from "../utils/utils";
import { staticTexts } from "../utils/Translate";

import "./breakingNewsSlider.m.css";

const BreakingNewsSliderView = ({
  lang,
  breakingNews = [],
  breakingNewsLoaded,
  updateInterval,
  breakingNewsUpdated,
  domainSlug,
  pageType,
  breakingApi,
  currentHostUrl,
}) => {
  if (
    checkRender(
      PAGE_TYPE_NOT_HEADER_FOOTER.concat(PREVIEW_PAGE_TYPES),
      pageType,
      DOMAIN_SLUG_NOT_BREAKING_NEWS,
      domainSlug
    )
  )
    return null;

  const [isSocialShare, setIsSocialShare] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showBreakingNews, setShowBreakingNews] = useState(false);

  const checkCacheIds = (stories = []) => {
    const cache = global.localStorage && global.localStorage.getItem("breakingIds");
    const storiesIds = stories.map(({ id }) => id).join();
    if (!cache || !storiesIds) return true;
    return storiesIds !== cache;
  };

  const updateBreakingNews = async () => {
    const data = await getBreakingNews(breakingApi);

    if (data && data.data.items && data.data.items.length) {
      const {
        data: { items },
      } = data;
      const stories = items.map(({ story }) => story);
      const idCheck = checkCacheIds(stories);
      setShowBreakingNews(idCheck);
      if (idCheck) breakingNewsUpdated(stories);
    }
  };
  useEffect(() => {
    updateBreakingNews();
    const clearId = setInterval(() => {
      updateBreakingNews();
    }, updateInterval || 60000);
    return () => clearInterval(clearId);
  }, []);

  const selectionChange = (index) => {
    setSelectedIndex(index);
  };

  // slider logics
  const processNewsElements = () => {
    return breakingNews.map((item, index) => {
      const linkedStorySlug = item?.metadata?.["linked-story-slug"];
      if (linkedStorySlug) {
        return (
          <div key={index} styleName="breaking-share-elements">
            <Anchor
              aria-label={item.headline || "breaking share elements"}
              styleName="link"
              href={`/${linkedStorySlug}`}
            >
              {item.headline}
            </Anchor>
          </div>
        );
      }
      return (
        <div key={index} styleName="link breaking-share-elements">
          {item.headline}
        </div>
      );
    });
  };

  const closeBreaking = () => {
    setShowBreakingNews(false);
    const breakingIds = breakingNews.map(({ id }) => id);
    if (localStorage && breakingIds.length > 0) {
      localStorage.setItem("breakingIds", breakingIds.join());
    }
  };

  const shareBreaking = () => {
    setIsSocialShare(!isSocialShare);
  };

  if (!(showBreakingNews && breakingNewsLoaded && breakingNews.length)) return null;

  return (
    <div styleName="breaking">
      <div styleName="breaking-icons">
        {breakingNews[selectedIndex] &&
        breakingNews[selectedIndex].metadata &&
        breakingNews[selectedIndex].metadata["linked-story-slug"] ? (
          <div title="share" styleName="breaking-share" onClick={shareBreaking}>
            <ShareIcon />
          </div>
        ) : null}
        <div title="close" styleName="breaking-close" onClick={closeBreaking}>
          <CloseIcon />
        </div>
      </div>
      <div styleName="breaking-wrapper">
        <div styleName="breaking-label">{staticTexts(lang).breakingNews}</div>
        <div styleName="breaking-share-elements">
          {isSocialShare &&
          breakingNews[selectedIndex] &&
          breakingNews[selectedIndex].metadata &&
          breakingNews[selectedIndex].metadata["linked-story-slug"] ? (
            <SocialShare
              template={ShareAllTemplate}
              title={breakingNews[selectedIndex].headline}
              fullUrl={currentHostUrl + "/" + breakingNews[selectedIndex].metadata["linked-story-slug"]}
              toggleShare={shareBreaking}
              isBreakingNews={true}
            />
          ) : null}
          <ScrollSnap
            leftArrow={<LeftArrowButton />}
            rightArrow={<RightArrowButton />}
            isIndicator={breakingNews.length > 1}
            isArrow={false}
            selectionChange={selectionChange}
            isInfinite={true}
            interval={10000}
            pauseOnHover={true}
            customStyle={true}
          >
            {processNewsElements()}
          </ScrollSnap>
        </div>
      </div>
    </div>
  );
};

BreakingNewsSliderView.propTypes = {
  breakingNews: array,
  lang: string,
  currentHostUrl: string,
  isVerticalMenubar: bool,
  breakingNewsLoaded: bool,
  breakingNewsUpdated: func,
  pageType: string,
  updateInterval: number,
  domainSlug: string,
  breakingApi: string,
};

const mapStateToProps = (state) => ({
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], ""),
  breakingApi: get(state, ["qt", "config", "publisher-attributes", "breaking_api"], null),
  domainSlug: get(state, ["qt", "config", "domainSlug"], ""),
  currentHostUrl: get(state, ["qt", "currentHostUrl"], []),
  breakingNews: get(state, ["breakingNews"], []),
  pageType: get(state, ["qt", "pageType"], ""),
  breakingNewsLoaded: get(state, ["breakingNewsLoaded"], false),
});

const mapDispatchToProps = (dispatch) => ({
  breakingNewsUpdated: (stories) => dispatch({ type: BREAKING_NEWS_UPDATED, stories: stories }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BreakingNewsSliderView);
