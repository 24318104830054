import { startApp } from "@quintype/framework/client/start";
import wretch from "wretch";

import { preRenderApplication, renderApplication } from "./render";

import REDUCERS from "../isomorphic/components/helper/reducers";

import "../../app/assets/stylesheets/app.scss";

const opts = {
  enableServiceWorker: process.env.NODE_ENV === "production",
  appVersion: require("../isomorphic/app-version"),
  preRenderApplication,
};

function enableHotReload(store) {
  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./render", () => {
      renderApplication(store);
      preRenderApplication(store);
    });
  }
}

global.wretch = wretch;
global.deviceWidth = window.innerWidth;
global.pe = window._pe;
global.print = window.print;

global.isTab = window.innerWidth > 875 && window.innerWidth <= 1263;
global.isMobile = window.innerWidth < 876;
global.isDesktop = window.deviceWidth > 1263;

startApp(renderApplication, REDUCERS, opts).then(enableHotReload);
