import React from "react";
import { string } from "prop-types";
import { connect } from "react-redux";

import { DfpComponent } from "../../ads/dfp-component";
import DfpContainer from "../../ads/dfp-container";

import { PREVIEW_PAGE_TYPES } from "../../../constants";
import { getSlotId } from "../../utils/utils";

const InterstitialAd = ({ lang, pageType, currentPath, domainSlug }) => {
  if (domainSlug !== null) {
    return null;
  }
  const ads = {
    "home-page": {
      en: "EnPalo_HP_Int_660x440",
      bn: "home_Int_660x440",
    },
    en: "EnPalo_Inner_Int_660x440",
    bn: "News_Int_660x440",
  };

  return (
    <DfpContainer pages={PREVIEW_PAGE_TYPES}>
      <DfpComponent
        type={ads[pageType]?.[lang] || ads[lang]}
        slotId={getSlotId("interstitial-ad", ads[pageType]?.[lang] || ads[lang], currentPath)}
      />
    </DfpContainer>
  );
};

InterstitialAd.propTypes = {
  lang: string,
  pageType: string,
  currentPath: string,
  domainSlug: string,
};

const mapStateToProps = (state) => ({
  lang: state?.qt?.config?.["publisher-attributes"]?.lang || "en",
  pageType: state?.qt?.pageType,
  currentPath: state?.qt?.currentPath,
  domainSlug: state?.qt?.config?.domainSlug,
});

export default connect(mapStateToProps)(InterstitialAd);
