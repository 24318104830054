import React, { useRef, useEffect } from "react";
import { element, func, string } from "prop-types";

import { SearchBox } from "../../../atoms/SearchBoxBase";
import Icon from "../../../icons";

import { staticTexts } from "../../../utils/Translate";

import "./navbarSearch.m.css";

const NavbarSearch = ({ device = "desktop", lang, toggleVerticalMenubar }) => {
  const textInput = useRef();
  const searchForm = useRef();

  const DrawForm = ({ children }) => [
    <label htmlFor="searchForm" key="1" styleName="search-label" className="search-label">
      {children}
    </label>,
    <button type="submit" styleName="search__form-submit" className="search__form-submit" key="2">
      <Icon type="search" className="search-icon" />
    </button>,
  ];

  DrawForm.propTypes = {
    children: element,
  };

  useEffect(() => {
    const inputElements = document.getElementsByTagName("input");
    Array.from(inputElements).forEach((item) => item.blur());
  }, []);

  return (
    <div styleName={`search ${device}`} className="search">
      <SearchBox
        styleName="search-box"
        inputId="searchForm"
        inputClassName={`search__form-input`}
        template={DrawForm}
        inputRef={textInput}
        formRef={searchForm}
        toggleVerticalMenubar={toggleVerticalMenubar}
        placeholder={staticTexts(lang).searchBoxText}
        className="search-box"
      />
    </div>
  );
};

NavbarSearch.propTypes = {
  device: string,
  lang: string,
  toggleVerticalMenubar: func,
};

export default NavbarSearch;
