import React from "react";
import { string } from "prop-types";

const LeftArrowButton = ({ color = "#007bbd", opacity = "0.7", color2 = "#ffffff", opacity2 = "1" }) => (
  <svg viewBox="0 0 24 24" width="42" height="42">
    <circle opacity={opacity} fill={color} cx="12" cy="12" r="10" />
    <path
      fill={color2}
      opacity={opacity2}
      d="M13.53,16.803L9.197,12.47c-0.073-0.073-0.124-0.146-0.153-0.219
	C9.015,12.179,9,12.099,9,12.011c0-0.087,0.015-0.168,0.044-0.241c0.029-0.073,0.08-0.146,0.153-0.219l4.354-4.354
	C13.683,7.066,13.84,7,14.022,7c0.182,0,0.339,0.066,0.47,0.197c0.131,0.131,0.193,0.292,0.186,0.481
	c-0.007,0.19-0.076,0.35-0.208,0.481l-3.851,3.851l3.873,3.873c0.131,0.131,0.197,0.285,0.197,0.46c0,0.175-0.066,0.328-0.197,0.46
	C14.361,16.934,14.201,17,14.011,17C13.821,17,13.661,16.934,13.53,16.803z"
    />
  </svg>
);

LeftArrowButton.propTypes = {
  color: string,
  color2: string,
  opacity: string,
  opacity2: string,
};

export default LeftArrowButton;
