import React from "react";
import { bool, object, string } from "prop-types";
import { Link } from "@quintype/components";

import { SvgIcon } from "../../icons";

import { getSiteLink } from "../../utils/utils";

import "./languageEdition.m.css";

function LanguageEdition({ publisherAttributes, className = "" }) {
  const { lang } = publisherAttributes;

  return (
    <Link
      styleName="version"
      className={`edition ${className}`}
      href={getSiteLink(publisherAttributes)}
      target="_blank"
      aria-label="Prothom alo"
    >
      <SvgIcon type="globe" styleName="globe" />
      <span styleName="text">{lang === "bn" ? "Eng" : "বাংলা"}</span>
    </Link>
  );
}

LanguageEdition.propTypes = {
  publisherAttributes: object,
  className: string,
  eng: bool,
};

export default LanguageEdition;
