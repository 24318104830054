import React from "react";
// eslint-disable-next-line react/prop-types
export default function Icon({ color = "#fff", width = "34", height = "34" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "auto", background: "none" }}
      width={width}
      height={height}
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <g transform="translate(84 50)">
        <circle r="3" fill={color} transform="scale(1.90075)">
          <animateTransform
            attributeName="transform"
            begin="-0.9285714285714286s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.9285714285714286s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(80.633 64.752) rotate(25.714)">
        <circle r="3" fill={color} fillOpacity="0.929" transform="scale(1.97075)">
          <animateTransform
            attributeName="transform"
            begin="-0.8571428571428571s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.8571428571428571s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(71.199 76.582) rotate(51.429)">
        <circle r="3" fill={color} fillOpacity="0.857" transform="scale(1.06075)">
          <animateTransform
            attributeName="transform"
            begin="-0.7857142857142857s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.7857142857142857s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(57.566 83.148) rotate(77.143)">
        <circle r="3" fill={color} fillOpacity="0.786" transform="scale(1.13075)">
          <animateTransform
            attributeName="transform"
            begin="-0.7142857142857143s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.7142857142857143s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(42.434 83.148) rotate(102.857)">
        <circle r="3" fill={color} fillOpacity="0.714" transform="scale(1.20075)">
          <animateTransform
            attributeName="transform"
            begin="-0.6428571428571429s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.6428571428571429s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(28.801 76.582) rotate(128.571)">
        <circle r="3" fill={color} fillOpacity="0.643" transform="scale(1.27075)">
          <animateTransform
            attributeName="transform"
            begin="-0.5714285714285714s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.5714285714285714s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(19.367 64.752) rotate(154.286)">
        <circle r="3" fill={color} fillOpacity="0.571" transform="scale(1.34075)">
          <animateTransform
            attributeName="transform"
            begin="-0.5s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.5s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(16 50) rotate(180)">
        <circle r="3" fill={color} fillOpacity="0.5" transform="scale(1.41075)">
          <animateTransform
            attributeName="transform"
            begin="-0.42857142857142855s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.42857142857142855s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(19.367 35.248) rotate(205.714)">
        <circle r="3" fill={color} fillOpacity="0.429" transform="scale(1.48075)">
          <animateTransform
            attributeName="transform"
            begin="-0.35714285714285715s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.35714285714285715s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(28.801 23.418) rotate(231.429)">
        <circle r="3" fill={color} fillOpacity="0.357" transform="scale(1.55075)">
          <animateTransform
            attributeName="transform"
            begin="-0.2857142857142857s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.2857142857142857s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(42.434 16.852) rotate(257.143)">
        <circle r="3" fill={color} fillOpacity="0.286" transform="scale(1.62075)">
          <animateTransform
            attributeName="transform"
            begin="-0.21428571428571427s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.21428571428571427s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(57.566 16.852) rotate(282.857)">
        <circle r="3" fill={color} fillOpacity="0.214" transform="scale(1.69075)">
          <animateTransform
            attributeName="transform"
            begin="-0.14285714285714285s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.14285714285714285s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(71.199 23.418) rotate(308.571)">
        <circle r="3" fill={color} fillOpacity="0.143" transform="scale(1.76075)">
          <animateTransform
            attributeName="transform"
            begin="-0.07142857142857142s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="-0.07142857142857142s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
      <g transform="translate(80.633 35.248) rotate(334.286)">
        <circle r="3" fill={color} fillOpacity="0.071" transform="scale(1.83075)">
          <animateTransform
            attributeName="transform"
            begin="0s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="scale"
            values="1.98 1.98;1 1"
          ></animateTransform>
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur="1s"
            keyTimes="0;1"
            repeatCount="indefinite"
            values="1;0"
          ></animate>
        </circle>
      </g>
    </svg>
  );
}
