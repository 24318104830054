import React from "react";
import { string } from "prop-types";

const YoutubeIcon = ({ color = "#eee" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.733" height="16.543">
    <g data-name="Group 587">
      <path
        d="M23.323 2.661A3 3 0 0020.757.324a79.746 79.746 0 00-8.887-.329 79.759 79.759 0 00-8.888.329A3 3 0 00.418 2.661 25.417 25.417 0 000 8.271a25.409 25.409 0 00.414 5.61 3 3 0 002.565 2.332 79.689 79.689 0 008.887.329 79.673 79.673 0 008.887-.329 3 3 0 002.565-2.332 25.4 25.4 0 00.415-5.61 25.414 25.414 0 00-.41-5.61zM8.8 11.955V4.106l7.545 3.936z"
        data-name="Path 19"
        fill={color}
      />
    </g>
  </svg>
);

YoutubeIcon.propTypes = {
  color: string
};

export default YoutubeIcon;
