import React, { useEffect, useState, useRef } from "react";
import { array, number, bool, func, string, object } from "prop-types";
import { connect } from "react-redux";

import LeftArrowButton from "../icons/left-arrow-button";
import RightArrowButton from "../icons/right-arrow-button";
import PlayPauseButton from "../icons/play-pause-icon";

import { toBanglaNum } from "../../utils/utils";

import "./srollSnap.m.css";

const ScrollSnap = ({
  children,
  defaultSelection,
  isArrow,
  isIndicator,
  interval,
  isInfinite,
  displaySliderCount,
  selectionChange,
  pauseOnHover,
  lang,
  customStyle,
  src,
  showPlayPauseBtn,
  hideDisableIcon,
  showProgessBar,
  slideNumOpen,
  story,
  navigateToPage,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultSelection ? defaultSelection - 1 : 0);
  const [autoScroll, setAutoScroll] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [countClick, setCountClick] = useState(0);

  const carouselStyle = customStyle ? "breakingnews-carousel carousel" : "carousel";
  const indicatorsStyle = customStyle ? "breakingnews-indicators indicators" : "indicators";
  const styleIndicator = customStyle ? "indicator-breakingnews indicator" : "indicator";
  const styleIndicatorButton = customStyle ? "breakingnews-indicators-btn indicator-button" : "indicator-button";
  const noOfItems = children ? children.length : 0;
  const scroller = useRef(null);

  useEffect(() => {
    if (scroller.current) {
      scroller.current.addEventListener(
        "scroll",
        debounce(() => {
          const index =
            src === "SixStoriesWithPoll"
              ? Math.ceil((scroller.current.scrollLeft / scroller.current.scrollWidth) * noOfItems) // for sixstorieswithpoll component will have to use the same ciel func for other components in future
              : Math.round((scroller.current.scrollLeft / scroller.current.scrollWidth) * noOfItems);
          setSelectedIndex(index);
        }, 150)
      );

      // For Default Selection if selectedIndex is not 0 then manually select it
      if (selectedIndex !== 0) {
        updateItemSelection(selectedIndex);
      }
    }

    return () => {
      scroller.current.removeEventListener("scroll", debounce);
    };
  }, []);

  useEffect(() => {
    if (selectedIndex !== defaultSelection) {
      updateItemSelection(defaultSelection);
    }
  }, [defaultSelection]);

  useEffect(() => {
    if (selectionChange) {
      selectionChange(selectedIndex);
    }

    isInfinite && !isPaused && setAutoScroll(false);
  }, [selectedIndex]);

  // Auto Scroll
  useEffect(() => {
    if (isInfinite && !autoScroll) {
      const timer = setInterval(() => {
        setSelectedIndex((value) => {
          const newIndex = value === noOfItems - 1 ? 0 : value + 1;

          updateItemSelection(newIndex);

          return newIndex;
        });
        setCountClick(0);
      }, interval);

      return () => {
        clearInterval(timer);
      };
    }
  }, [autoScroll]);

  const debounce = (func, ms) => {
    let timeout;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func();
      }, ms);
    };
  };

  const smoothScroll = (node, topOrLeft, horizontal) => {
    return node.scrollTo({
      [horizontal ? "left" : "top"]: topOrLeft,
      behavior: "smooth",
    });
  };

  const indicatorClick = (e, newIndex) => {
    e.preventDefault();
    e.stopPropagation();

    if (scroller.current) {
      setSelectedIndex(newIndex);
      updateItemSelection(newIndex);
    }
  };

  const nextClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!(selectedIndex < noOfItems - 1)) return;

    setCountClick((prevState) => prevState + 1);

    if (slideNumOpen > 0 && slideNumOpen === countClick + 1) {
      navigateToPage(`/${story.slug}`);
      return;
    }

    isInfinite && setAutoScroll(true);

    if (scroller.current) {
      if (selectedIndex < noOfItems) {
        const newIndex = selectedIndex + 1;
        setSelectedIndex(newIndex);
        updateItemSelection(newIndex);
      }
    }
  };

  const previousClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (selectedIndex === 0) return;

    countClick > 0 && setCountClick((prevState) => prevState - 1);

    isInfinite && setAutoScroll(true);

    if (scroller.current) {
      const newIndex = selectedIndex - 1;
      setSelectedIndex(newIndex);
      updateItemSelection(newIndex);
    }
  };

  const updateItemSelection = (index) => {
    const scrollLeft = Math.floor(scroller.current.scrollWidth * (index / noOfItems));
    smoothScroll(scroller.current, scrollLeft, true);
  };

  const arrowView = () => (
    <React.Fragment>
      {hideDisableIcon && selectedIndex === 0 ? null : (
        <span
          onClick={previousClick}
          styleName={`left-arrow-icon ${selectedIndex === 0 ? "disable-arrow" : ""}`}
          className={`left-arrow ${isInfinite ? "auto-play" : ""} ${selectedIndex === 0 ? "disable-slider-arrow" : ""}`}
        >
          <LeftArrowButton
            color="var(--rgbBlack)"
            opacity={selectedIndex === 0 ? "var(--opacity-38)" : "var(--opacity-60)"}
            opacity2={selectedIndex === 0 ? "var(--opacity-38)" : "var(--opacity-100)"}
            color2="var(--white)"
          />
        </span>
      )}

      {isInfinite && showPlayPauseBtn && (
        <span styleName="pause-play-icon" className="pause-play-icon" onClick={pauseAutoPlay}>
          <PlayPauseButton isPlaying={isPaused} />
        </span>
      )}

      {customArrowView() && (
        <span
          onClick={nextClick}
          styleName={`right-arrow-icon ${selectedIndex < noOfItems - 1 ? "" : "disable-arrow"}`}
          className={`right-arrow ${selectedIndex < noOfItems - 1 ? "" : "disable-slider-arrow"}`}
        >
          <RightArrowButton
            color="var(--rgbBlack)"
            color2="var(--white)"
            opacity={selectedIndex < noOfItems - 1 ? "var(--opacity-60)" : "var(--opacity-38)"}
            opacity2={selectedIndex < noOfItems - 1 ? "var(--opacity-100)" : "var(--opacity-38)"}
          />
        </span>
      )}
    </React.Fragment>
  );

  const pauseAutoPlay = () => {
    setAutoScroll(!autoScroll);
    setIsPaused(!isPaused);
  };

  const customArrowView = () => {
    if (src === "SliderFocusedCard") {
      if (global.isDesktop && selectedIndex >= children.length - 4) {
        return false;
      } else if (global.isTab && selectedIndex >= children.length - 3) {
        return false;
      } else return true;
    }

    return !(hideDisableIcon && selectedIndex >= noOfItems - 1);
  };

  return (
    <>
      <div
        styleName="wrapper"
        className="scroll-snap-wrapper"
        style={{ "--interval": interval + "ms" }}
        onMouseEnter={pauseOnHover ? () => pauseAutoPlay() : null}
        onMouseLeave={pauseOnHover ? () => pauseAutoPlay() : null}
      >
        {showProgessBar && isInfinite && !autoScroll && <div styleName="slider-progress" />}

        <div ref={scroller} styleName={carouselStyle} className="scroll-snap-carousel">
          {children}
        </div>
        {displaySliderCount && (
          <div styleName="slider-count">{`${lang === "en" ? selectedIndex + 1 : toBanglaNum(selectedIndex + 1)}/${
            lang === "en" ? children.length : toBanglaNum(children.length)
          }`}</div>
        )}
        {isArrow ? arrowView() : null}
      </div>

      {isIndicator ? (
        <ul className="indicators" styleName={indicatorsStyle}>
          {[...Array(noOfItems).keys()].map((index) => (
            <li key={index} styleName={styleIndicator} className="indicator" onClick={(e) => indicatorClick(e, index)}>
              <button
                styleName={styleIndicatorButton}
                className="indicator-button"
                aria-label="indicator button"
                aria-pressed={selectedIndex === index ? "true" : "false"}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

ScrollSnap.defaultProps = {
  isArrow: true,
  isIndicator: true,
  defaultSelection: 0,
  isInfinite: false,
  interval: 5000,
  displaySliderCount: false,
  pauseOnHover: false,
  customStyle: false,
  src: "",
  hideDisableIcon: true,
  showProgessBar: false,
  story: null,
};

ScrollSnap.propTypes = {
  children: array.isRequired,
  defaultSelection: number,
  isArrow: bool,
  isIndicator: bool,
  isInfinite: bool,
  interval: number,
  displaySliderCount: bool,
  selectionChange: func,
  lang: string,
  pauseOnHover: bool,
  customStyle: bool,
  src: string,
  showPlayPauseBtn: bool,
  hideDisableIcon: bool,
  showProgessBar: bool,
  slideNumOpen: number,
  story: object,
  navigateToPage: func,
};

const mapDispatchToProps = (dispatch) => ({
  navigateToPage: (url) => global.app.navigateToPage(dispatch, url),
});

export default connect(null, mapDispatchToProps)(ScrollSnap);
