import React from "react";
import { Link } from "@quintype/components";
import { func, object, string } from "prop-types";

import Anchor from "../../../../atoms/Anchor";

const HamItem = ({ menu, click = () => {}, className = "" }) => {
  const { title, "item-type": type, completeUrl = "/" } = menu;
  const tags = {
    placeholder: "span",
    link: Anchor,
  };

  let ops = {};
  if (type !== "placeholder") {
    ops = {
      href: completeUrl,
    };
  }

  const Tag = tags[type] || Link;

  return (
    <Tag {...ops} className={className + (type === "placeholder" ? " placeholder" : "")}>
      <span onClick={type !== "placeholder" ? click : () => {}}>{title}</span>
    </Tag>
  );
};

HamItem.propTypes = {
  menu: object,
  click: func,
  className: string,
};

export default HamItem;
