import React from "react";
import { object, string } from "prop-types";

import Anchor from "../Anchor";
import LiveText from "../LiveText";
import Icons, { SvgIcon } from "../../icons";

import { getHeadline } from "../../utils/utils";
import { staticTexts } from "../../utils/Translate";

import "./story-type-with-text.m.css";

const StoryTypeWithText = ({ story, className = "", lang }) => {
  if (!story) return null;

  const { "story-template": type } = story;
  const iconStory = ["visual-story", "interview", "shorts", "video", "photo"];

  const isLive = !story.metadata?.["is-closed"] && story["story-template"] === "live-blog";

  return (
    <Anchor href={story.url} styleName="base" className={className} aria-label={story.headline} access={story.access}>
      {isLive ? (
        <LiveText isClosed={story.metadata?.["is-closed"]} lastPublishedAt={story["last-published-at"]} />
      ) : (
        <>
          {story.access === "login" ? <Icons type="star" styleName="icon" /> : <SvgIcon styleName="icon" type={type} />}

          <h2 styleName="headline" className="header-headline-mobile">
            {!iconStory.includes(type) ? getHeadline(story) : staticTexts(lang)[type]}
          </h2>
        </>
      )}
    </Anchor>
  );
};

StoryTypeWithText.propTypes = {
  story: object,
  className: string,
  lang: string,
};

export default StoryTypeWithText;
