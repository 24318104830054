import React from "react";
import { bool, func, string } from "prop-types";

import Anchor from "../Anchor";
import { SvgIcon } from "../../icons";

import { staticTexts } from "../../utils/Translate";

import "./nav-sarch.m.css";

const NavSearch = ({ lang, showText = true, onClick = () => {} }) => (
  <Anchor styleName="base" aria-label="Prothom alo search" className="search" href="/search">
    <div onClick={onClick} styleName="wrapper">
      <SvgIcon type="search" styleName="search" />
      {showText && <span styleName="text">{staticTexts(lang).search}</span>}
    </div>
  </Anchor>
);

NavSearch.propTypes = {
  lang: string,
  showText: bool,
  onClick: func,
};

export default NavSearch;
