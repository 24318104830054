import React from "react";
import { string } from "prop-types";

const WatsAppIcon = ({ fontColor = "#121212", bgColor = "#EEEEEE" }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 28 28"
    width="28"
    height="28"
  >
    <circle id="Ellipse_3" fill={bgColor} cx="14" cy="14" r="13.5" />
    <path
      id="Path_525"
      fill={fontColor}
      d="M19.6,8.3c-3.1-3.1-8.1-3.1-11.2,0c-2.5,2.5-3,6.5-1.3,9.6L6,22l4.2-1.1c1.2,0.6,2.5,1,3.8,1l0,0
	c4.4,0.1,7.9-3.4,8-7.8C22,11.9,21.2,9.8,19.6,8.3L19.6,8.3z M14,20.5c-1.2,0-2.3-0.3-3.4-0.9l-0.2-0.1l-2.5,0.7l0.7-2.4l-0.2-0.2
	c-1.9-3.1-0.9-7.2,2.2-9.1c3.1-1.9,7.2-0.9,9.1,2.2c0.6,1,0.9,2.2,1,3.3C20.6,17.6,17.7,20.5,14,20.5z M17.6,15.6
	c-0.2-0.1-1.2-0.6-1.4-0.6s-0.3-0.1-0.4,0.1s-0.5,0.6-0.6,0.8S15,16,14.8,15.9c-1.1-0.5-2.1-1.3-2.7-2.4c-0.2-0.4,0.2-0.3,0.6-1.1
	c0.1-0.1,0-0.2,0-0.3C12.6,12,12.2,11,12,10.6s-0.3-0.3-0.4-0.3s-0.2,0-0.4,0c-0.2,0-0.4,0.1-0.5,0.2c-0.5,0.4-0.7,1-0.7,1.7
	c0.1,0.7,0.3,1.5,0.8,2.1c0.9,1.3,2,2.3,3.4,3c0.7,0.4,1.6,0.6,2.4,0.5c0.6-0.1,1-0.5,1.3-0.9c0.1-0.3,0.2-0.6,0.1-0.9
	C17.9,15.7,17.8,15.7,17.6,15.6L17.6,15.6z"
    />
  </svg>
);

WatsAppIcon.propTypes = {
  fontColor: string,
  bgColor: string,
};

export default WatsAppIcon;
