import React from "react";
import { number } from "prop-types";

export function WhiteCloseIcon({ width = 21, height = 21 }) {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#121212"
        d="M9,7.115l6.6-6.6L17.485,2.4l-6.6,6.6l6.6,6.6L15.6,17.485l-6.6-6.6l-6.6,6.6L0.515,15.6l6.6-6.6l-6.6-6.6
   L2.4,0.515L9,7.115z"
      />
    </svg>
  );
}

WhiteCloseIcon.propTypes = {
  width: number,
  height: number,
};
