import React from "react";
import { string } from "prop-types";

const RightArrowButton = ({ color = "#007bbd", opacity = "0.7", color2 = "#ffffff", opacity2 = "1" }) => (
  <svg viewBox="0 0 24 24" width="42" height="42">
    <circle opacity={opacity} fill={color} cx="12" cy="12" r="10" />
    <path
      fill={color2}
      opacity={opacity2}
      d="M9.187,16.813c-0.117-0.146-0.179-0.306-0.186-0.481
	c-0.007-0.175,0.055-0.328,0.186-0.46l3.851-3.851L9.165,8.149c-0.117-0.117-0.171-0.273-0.164-0.47
	c0.007-0.197,0.069-0.354,0.186-0.47c0.146-0.146,0.303-0.215,0.47-0.208c0.168,0.007,0.317,0.077,0.449,0.208l4.354,4.354
	c0.073,0.073,0.124,0.146,0.153,0.219c0.029,0.073,0.044,0.153,0.044,0.241c0,0.087-0.015,0.168-0.044,0.241
	c-0.029,0.073-0.08,0.146-0.153,0.219l-4.332,4.332c-0.131,0.131-0.284,0.193-0.459,0.186C9.493,16.992,9.332,16.93,9.187,16.813z"
    />
  </svg>
);

RightArrowButton.propTypes = {
  color: string,
  color2: string,
  opacity: string,
  opacity2: string,
};

export default RightArrowButton;
