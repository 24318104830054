import { pickComponentHelper } from "@quintype/framework/server/pick-component-helper";

import { PAGE_TYPE } from "./constants";

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: "home", component: "HomePage" },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "list", component: "SectionPage" },
    [PAGE_TYPE.COLLECTION_PAGE]: { chunk: "list", component: "CollectionPage" },
    [PAGE_TYPE.TAG_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.NOTIFICATION_PAGE]: { chunk: "list", component: "NotificationPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.SAVED_PAGE]: { chunk: "list", component: "SavedPage" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "list", component: "AuthorPage" },
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.CATALOG_PAGE]: { chunk: "list", component: "CatalogPage" },
    [PAGE_TYPE.STORY_PREVIEW]: { chunk: "story", component: "StoryPagePreview" },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: { chunk: "story", component: "StoryPagePreview" },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: "home", component: "HomePagePreview" },
    [PAGE_TYPE.FORGOT_PASSWORD_PAGE]: { chunk: "list", component: "ForgotPasswordPage" },
    [PAGE_TYPE.RESET_PASSWORD_PAGE]: { chunk: "list", component: "ResetPasswordPage" },
    [PAGE_TYPE.PROFILE_PAGE]: { chunk: "list", component: "ProfilePage" },
    [PAGE_TYPE.EDIT_PROFILE_PAGE]: { chunk: "list", component: "EditProfilePage" },
    [PAGE_TYPE.CHANGE_PASSWORD_PAGE]: { chunk: "list", component: "ChangePasswordPage" },
    [PAGE_TYPE.COMMENTS_PAGE]: { chunk: "list", component: "CommentsPage" },
    [PAGE_TYPE.LOGIN_PAGE]: { chunk: "list", component: "LoginPage" },
    [PAGE_TYPE.SIGNUP_PAGE]: { chunk: "list", component: "SignUpPage" },
    [PAGE_TYPE.POLL_COUNTER_PAGE]: { chunk: "list", component: "PollCounterPage" },
    [PAGE_TYPE.UGC_PAGE]: { chunk: "list", component: "UgcPage" },
    [PAGE_TYPE.FOR_YOU]: { chunk: "list", component: "ForYouPage" },
    default: { chunk: "list", component: "NotFoundPage" },
  },
  {
    list: () => import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () => import(/* webpackChunkName: "story" */ "./component-bundles/story.js"),
    home: () => import(/* webpackChunkName: "home" */ "./component-bundles/home.js"),
  }
);

export { pickComponent, getChunkName };
