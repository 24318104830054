import { useEffect, useRef, useState } from "react";

export const importScript = (src, attributes = {}, target = "body", pageType = "") => {
  useEffect(() => {
    if (window) {
      const script = document.createElement("script");
      script.src = src;
      for (const attribute in attributes) {
        script[attribute] = attributes[attribute];
      }
      document[target].appendChild(script);
      return () => {
        document[target].removeChild(script);
      };
    }
  }, [src, pageType]);
};

export const useAutoRefresh = (name = "") => {
  useEffect(() => {
    if (window) {
      let timeOut;
      let time = new Date().getTime();
      const updateActivityTime = () => {
        time = new Date().getTime();
      };
      const refresh = () => {
        if (new Date().getTime() - time >= 600000) {
          window.location.reload();
        } else {
          timeOut = setTimeout(refresh, 10000);
        }
      };

      document.addEventListener("scroll", updateActivityTime);
      timeOut = setTimeout(refresh, 10000);

      return () => {
        document.removeEventListener("scroll", updateActivityTime);
        clearTimeout(timeOut);
      };
    }
  }, [name]);
};
export const useDevice = () => {
  const [device, setDevice] = useState({});

  const updateSize = () => {
    const tab = window.innerWidth > 875 && window.innerWidth <= 1263;
    const mobile = window.innerWidth < 876;
    const desktop = window.deviceWidth > 1263;
    setDevice({
      name: mobile ? "mobile" : tab ? "tab" : desktop ? "desktop" : "",
      tab,
      mobile,
      desktop,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return device;
};

export const useDeviceType = () => {
  const [device, setDevice] = useState({});

  const updateSize = () => {
    const tab = window.innerWidth > 833 && window.innerWidth < 1280;
    const mobile = window.innerWidth < 834;
    const desktop = window.deviceWidth >= 1280;

    setDevice({
      name: mobile ? "mobile" : tab ? "tab" : desktop ? "desktop" : "",
      tab,
      mobile,
      desktop,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return device;
};

export function useOuterClick(callback) {
  const callbackRef = useRef();
  const innerRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);

    function handleClick(e) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) callbackRef.current(e);
    }
  }, []);

  return innerRef;
}
