import React from "react";
import { array, arrayOf, bool, node, oneOfType, string } from "prop-types";
import { connect } from "react-redux";

import { checkRender } from "../../utils/utils";

import { PAGE_TYPES_NOT_ADS } from "../../../constants";

const DfpContainer = ({ children, pageType, pages = [], domains = [], domainSlug, isRender = true, ...ops }) => {
  if (!isRender) {
    return <div {...ops} />;
  }

  if (checkRender([...pages, ...PAGE_TYPES_NOT_ADS], pageType, [...domains, "trust"], domainSlug)) {
    return null;
  }

  delete ops.dispatch;

  return <div {...ops}>{children}</div>;
};

DfpContainer.propTypes = {
  pageType: string,
  children: oneOfType([arrayOf(node), node]),
  pages: array,
  domainSlug: string,
  domains: array,
  isRender: bool,
};

const mapStateToProps = (state) => ({
  domainSlug: state?.qt?.config?.domainSlug,
  pageType: state?.qt?.pageType,
});

export default connect(mapStateToProps)(DfpContainer);
