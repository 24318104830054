import React from "react";
import { shape, func, bool, string } from "prop-types";

import Anchor from "../../atoms/Anchor";

const MenuItem = ({ item, itemClick }) => {
  const { "item-type": type, title = "", "menu-group-slug": menuGroupSlug, completeUrl } = item;
  if (type === "placeholder") {
    return <span>{title}</span>;
  } else if (type === "link" && menuGroupSlug !== "prothomalo") {
    return (
      <a aria-label={title} href={completeUrl} onClick={itemClick}>
        {title}
      </a>
    );
  }
  return (
    <Anchor aria-label={title} href={completeUrl} callback={itemClick}>
      {title}
    </Anchor>
  );
};

MenuItem.propTypes = {
  item: shape({
    isExternalLink: bool,
    completeUrl: string,
    title: string,
  }),
  itemClick: func,
  dataTagCategory: string,
};

export { MenuItem };
