import React from "react";
import { string } from "prop-types";

import AuthView from "../../../rows/AuthView";

import "./uniformHeader.m.css";

const LoginContainer = ({ loginBorderStyle, loginTextStyle, domainSlug, donateButtonLink }) => {
  return (
    <>
      {domainSlug === "trust" && (
        <a styleName="trust-donate" href={donateButtonLink} target="_blank" rel="noreferrer">
          Donate
        </a>
      )}
      <AuthView borderColor={loginBorderStyle} textColor={loginTextStyle} />
    </>
  );
};

LoginContainer.propTypes = {
  loginBorderStyle: string,
  loginTextStyle: string,
  domainSlug: string,
  donateButtonLink: string,
};

export default LoginContainer;
