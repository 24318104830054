import React from "react";
import { func, bool } from "prop-types";

import "./hamburger.m.css";

const HamburgerIcon = ({ onMenuToggle, isNavbarOpen }) => (
  <div
    onClick={onMenuToggle}
    styleName={`hamburger ${!global.isDesktop && isNavbarOpen ? "is-open" : ""}`}
    className="hamburger"
  >
    <div styleName="line" className="hamburger-line" />
    <div styleName="line" className="hamburger-line" />
    <div styleName="line" className="hamburger-line" />
  </div>
);

export default HamburgerIcon;

HamburgerIcon.propTypes = {
  onMenuToggle: func,
  isNavbarOpen: bool,
};
