import React, { useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import get from "lodash/get";
import { string } from "prop-types";

import "./gdprPolicy.m.css";

const GdprPolicy = ({ domainLink, pageType, domainSlug }) => {
  if (typeof window !== "undefined") {
    const uuidCookies = new Cookies();

    if (!uuidCookies.get("uuid_v2")) {
      const navigatorInfo = window.navigator;
      const screenInfo = window.screen;
      const currentDate = new Date();
      let uid = "a";
      uid += navigatorInfo.mimeTypes.length;
      uid += navigatorInfo.userAgent.replace(/\D+/g, "");
      uid += navigatorInfo.plugins.length;
      uid += screenInfo.height || "";
      uid += screenInfo.width || "";
      uid += screenInfo.pixelDepth || "";
      uid += screenInfo.colorDepth || "";
      uid += screenInfo.top || "";
      uid += currentDate.getFullYear() || "";
      uid += currentDate.getMonth() || "";
      uid += currentDate.getDate() || "";
      uid += currentDate.getHours() || "";

      uuidCookies.set("uuid_v2", uid, { path: "/", maxAge: 60 * 60 * 24 * 365 });
    }
  }

  const [showGdpr, setShowGdpr] = useState(false);
  useEffect(() => {
    if (
      domainSlug !== "trust" &&
      pageType !== "home-preview" &&
      pageType !== "story-preview" &&
      pageType !== "story-public-preview-page" &&
      pageType !== "sign-in-page" &&
      pageType !== "sign-up-page" &&
      pageType !== "static-page" &&
      pageType !== "forgot-password-page"
    ) {
      setShowGdpr(true);
    }
  }, [pageType]);

  if (getCookieConsentValue()) {
    const cookies = new Cookies();
    cookies.set("CookieConsent", true, { path: "/", maxAge: 60 * 60 * 24 * 365 });
  }
  return showGdpr ? (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="OK"
        containerClasses="gdpr-wrapper"
        style={{
          background: "#001246",
          fontSize: "14px",
          padding: "8px",
          fontFamily: "Roboto, Arial, Helvetica, sans-serif",
          display: "grid",
          gridAutoFlow: "column",
          justifyContent: "center",
        }}
        buttonStyle={{
          background: "#0D47A1",
          color: "#fff",
          fontSize: "14px",
          borderRadius: "3px",
          padding: "3px 8px",
          margin: "0 0 0 12px",
        }}
        contentStyle={{ margin: "auto" }}
      >
        By using this site, you agree to our{" "}
        <a
          href={`${domainLink}/privacy-policy`}
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: "underline", color: "#fff" }}
        >
          Privacy Policy
        </a>
        .
      </CookieConsent>
    </div>
  ) : null;
};

GdprPolicy.propTypes = {
  domainLink: string,
  pageType: string,
  domainSlug: string,
};

const mapStateToProps = (state) => ({
  domainLink: get(state, ["qt", "currentHostUrl"], ""),
  pageType: get(state, ["qt", "pageType"], ""),
  domainSlug: get(state, ["qt", "config", "domainSlug"]),
});

export default connect(mapStateToProps)(GdprPolicy);
