import { any, bool, element, func, object, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";

import Icons from "../../icons";

class SearchBoxBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: this.props.initValue,
    };

    this.clearHandler = this.clearHandler.bind(this);
  }

  static defaultTemplate({ children }) {
    return children;
  }

  submit(q = "") {
    q = String(q || "").trim();
    if (this.props.query && Object.keys(this.props.query).length > 0) {
      const query = this.props.query;
      query.q = encodeURI(q);
      !q && delete query.q;

      const queryString = new URLSearchParams(query).toString();
      this.props.navigateTo(`/search?${queryString}`);
      return;
    }

    this.props.navigateTo(`/search?${q ? "q=" + encodeURI(q) : ""}`);
  }

  onSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const q = formData.get("q");
    this.submit(q);
    this.props.onSubmitHandler && this.props.onSubmitHandler(q);
  }

  keyPress(e) {
    if (e.keyCode === 27) this.props.onEscape && this.props.onEscape();
  }

  clearHandler() {
    if (this.props.query.q) {
      this.submit();
    }
  }

  render() {
    const Render = this.props.template || this.defaultTemplate;
    return (
      <form
        role="search"
        onSubmit={(e) => this.onSubmit(e)}
        className={this.props.className + " search-form"}
        ref={this.props.formRef}
      >
        <Render>
          <input
            type="search"
            name="q"
            placeholder={this.props.placeholder}
            defaultValue={this.props.initValue}
            className={this.props.inputClassName}
            onChange={(e) =>
              this.setState({
                query: e.target.value,
              })
            }
            id={this.props.inputId || "search-input-id"}
            ref={this.props.inputRef}
            onKeyDown={(e) => this.keyPress(e)}
          />
        </Render>
        {this.props.isClear && this.state.query && (
          <button onClick={this.clearHandler} type="button" className="clear-button" key="1">
            <Icons type="close" />
          </button>
        )}
      </form>
    );
  }
}

SearchBoxBase.propTypes = {
  initValue: string,
  placeholder: string,
  className: string,
  formRef: any,
  inputRef: any,
  inputClassName: string,
  template: element,
  inputId: string,
  onSubmitHandler: func,
  onEscape: func,
  navigateTo: func,
  isClear: bool,
  q: string,
  query: object,
};
const mapDispatchToProps = (dispatch) => ({
  navigateTo: function (url) {
    global.app.navigateToPage(dispatch, url);
  },
});

export const SearchBox = connect(null, mapDispatchToProps)(SearchBoxBase);
