import React, { useState, useEffect } from "react";
import { string, bool, func } from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import { Email, CopyLink, Linkedin, Twitter, Facebook } from "../../atoms/icons/StoryPageIcons";
import HamburgerIcon from "../../atoms/icons/hamburger";
import WatsAppIcon from "../../atoms/icons/WatsAppIcon";

import { staticTexts } from "../../utils/Translate";

import "./shareAllTemplate.m.css";

const ShareAllTemplate = ({
  twitterUrl,
  fbUrl,
  mailtoUrl,
  linkedinUrl,
  lang,
  onScroll,
  toggleShare2,
  toggleShare,
  whatsappUrl,
  fullUrl,
}) => {
  const [showCopy, setShowCopy] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowCopy(false);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const copyToClipboard = (storyUrl) => {
    const textArea = document.createElement("textarea");
    document.body.appendChild(textArea);
    textArea.value = storyUrl;
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    setShowCopy(true);
  };

  return (
    <div styleName="icon-wrapper" className="share-all-template">
      <div>
        {global.isMobile && (
          <span onClick={onScroll ? toggleShare2 : toggleShare}>
            <HamburgerIcon isNavbarOpen={true} />
          </span>
        )}
        <div styleName="share-text">{staticTexts(lang).share}</div>
        <div styleName="share-icon">
          <Link aria-label={staticTexts(lang).shareOnFacebook} href={fbUrl} target="_blank" rel="noopener noreferrer">
            <Facebook color1="#3f99ff" color2="#fff" />{" "}
            <span styleName="share-title">{staticTexts(lang).shareOnFacebook}</span>
          </Link>
        </div>
        <div styleName="share-icon email-icon">
          <Link aria-label="Social share link" href={mailtoUrl} target="_blank" rel="noopener noreferrer">
            <Email /> <span styleName="share-title">{staticTexts(lang).pleaseEmail}</span>
          </Link>
        </div>
        <div styleName="share-icon copy-icon">
          <input type="hidden" id="input-url" value="Copied!"></input>
          <span onClick={(e) => copyToClipboard(fullUrl)} styleName="copy-link">
            <CopyLink /> <span styleName="share-title">{staticTexts(lang).copyLink}</span>
            {showCopy && <span styleName="copy-popup">Link Copied</span>}
          </span>
        </div>
      </div>
      <div styleName="second-column">
        <div styleName="share-icon">
          <Link
            aria-label={staticTexts(lang).shareOnLinkedIn}
            href={linkedinUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin"
          >
            <Linkedin /> <span styleName="share-title">{staticTexts(lang).shareOnLinkedIn}</span>
          </Link>
        </div>
        <div styleName="share-icon">
          <Link
            aria-label={staticTexts(lang).shareOnTwitter}
            href={twitterUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter color1="#3f99ff" color2="#fff" />{" "}
            <span styleName="share-title">{staticTexts(lang).shareOnTwitter}</span>
          </Link>
        </div>
        <div styleName="share-icon">
          <Link
            aria-label={staticTexts(lang).shareOnWatsapp}
            href={whatsappUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WatsAppIcon bgColor="#4AC959" fontColor="#ffffff" />
            <span styleName="share-title">{staticTexts(lang).shareOnWatsapp}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

ShareAllTemplate.propTypes = {
  twitterUrl: string,
  fbUrl: string,
  mailtoUrl: string,
  linkedinUrl: string,
  lang: string,
  onScroll: bool,
  toggleShare2: func,
  toggleShare: func,
  whatsappUrl: string,
  fullUrl: string,
};

const mapStateToProps = (state) => ({
  lang: get(state, ["qt", "config", "publisher-attributes", "lang"], ""),
});

export default connect(mapStateToProps)(ShareAllTemplate);
