import React from "react";
import { string } from "prop-types";

const TwitterIcon = ({ color = "#eee" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
    height="20"
    width="20"
  >
    <path
      fill={color}
      d="M18.9,1h3.682l-8.042,9.383L24,23.154h-7.406l-5.804-7.743l-6.634,7.743H0.469l8.6-10.038L0,1h7.593
	l5.241,7.078L18.9,1z M17.606,20.906h2.039L6.482,3.13h-2.19L17.606,20.906z"
    />
  </svg>
);

TwitterIcon.propTypes = {
  color: string,
};

export default TwitterIcon;
