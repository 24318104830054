import React from "react";
import { array, bool, number, object, string } from "prop-types";
import { LazyLoadImages, WithLazy } from "@quintype/components";

import Image from "../Image";
import Icon from "../../icons";
import Anchor from "../Anchor";

import "./cardImage.m.css";

const CardImage = ({
  story = {},
  aspectRatio = [1, 1],
  defaultWidth = 500,
  imageType = "image1x1",
  zoom = true,
  widths = [250, 480, 764],
  eager = false,
  showStoryTypeIcon = true,
  showAlternateImage = true,
  defaultImage = true,
  className = "",
  linkEnable = false,
}) => {
  const wrapperStyleName = `qt-image-dynamic ${imageType}`;
  const { headline, url, access } = story;
  const alternativeHeroImage = story.alternative?.home?.default?.["hero-image"];

  const {
    "hero-image-caption": heroImageCaption,
    "hero-image-metadata": heroImageMetaData,
    "hero-image-s3-key": heroImage = showAlternateImage && alternativeHeroImage ? alternativeHeroImage.key : undefined,
  } = showAlternateImage ? alternativeHeroImage || story : story;

  const templates = {
    video: "video-play",
    photo: "photo-camera",
    "visual-story": "visual-story",
    shorts: "shorts",
  };

  const imageLayout = (children) => (
    <div
      styleName={`${wrapperStyleName} card-image-wrapper`}
      style={{ backgroundImage: "url(/media-placeholder.svg)" }}
      className={`${className} ${wrapperStyleName} card-image-wrapper`}
    >
      <figure className="qt-figure">
        <picture className={`qt-image ${zoom ? "zoom-desktop" : ""} ${showAlternateImage ? "default" : ""}`}>
          {children}
        </picture>
      </figure>
      {showStoryTypeIcon && templates[story["story-template"]] && (
        <span styleName="story-type-wrapper" className="story-type-wrapper">
          <Icon type={templates[story["story-template"]]} styleName="story-icon" className="story-icon" />
        </span>
      )}
    </div>
  );

  const imageContainer = (children) => {
    return linkEnable ? (
      <Anchor aria-label={headline} href={url} access={access}>
        {imageLayout(children)}
      </Anchor>
    ) : (
      imageLayout(children)
    );
  };

  const loadImage = () => (
    <Image
      slug={heroImage}
      metadata={heroImageMetaData}
      aspectRatio={aspectRatio}
      defaultWidth={defaultWidth}
      widths={widths}
      className="image"
      data-bg="/media-placeholder.svg"
      fetchpriority={eager ? "high" : "low"}
      alt={heroImageCaption || headline}
    />
  );

  if (!heroImage) {
    return defaultImage
      ? imageContainer(<Icon className={`${className} picture`} type="media-placeholder" styleName="default-image" />)
      : null;
  }

  return imageContainer(
    eager ? loadImage() : <WithLazy margin="50px">{() => <LazyLoadImages>{loadImage()}</LazyLoadImages>}</WithLazy>
  );
};

CardImage.propTypes = {
  story: object,
  defaultWidth: number,
  imageType: string,
  widths: array,
  zoom: bool,
  eager: bool,
  aspectRatio: array,
  showStoryTypeIcon: bool,
  showAlternateImage: bool,
  defaultImage: bool,
  className: string,
  linkEnable: bool,
};

export default CardImage;
